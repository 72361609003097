import React, { useState, useEffect, Fragment } from 'react';
import Button from '@mui/joy/Button';
import { useForm } from "react-hook-form";

function UpdateMessage({ orderData, userId, userToken, userEmail, createmessage, messageresult }) {
  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const [getMessage, setMessage] = useState('');

  function submitMessage(data) {
    createmessage({
      id: orderData?.id,
      order_number: orderData?.order_number,
      message: data.message,
      userId: userId,
      userToken: userToken,
      user_email: userEmail
    })
  }

  useEffect(() => {
    if (messageresult?.isSuccess) {
      reset()
    }
  }, [messageresult]);

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <form onSubmit={handleSubmit(submitMessage)}>
            <div className='form-group' style={{ display: 'flex', margin: 0 }}>
              <textarea
                style={{ height: '46px' }}
                className='form-control'
                id="message"
                aria-invalid={errors.message ? "true" : "false"}
                {...register('message', { required: true })}
              >
              </textarea>

              <Button
                type='submit'
                disabled={messageresult?.isLoading}
                style={{ textTransform: 'capitalize', fontWeight: 400, margin: '0px 5px' }}
                variant="soft"
                size="sm"
              >
                {messageresult?.isLoading ? 'updating...' : 'Send'}
              </Button>
            </div>
          </form>
        </div>
      </div>
    </>
  )

}

export default UpdateMessage;
