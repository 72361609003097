import React from 'react';
import { useLocation } from "react-router-dom";
import ProductsC from '../../components/ProductsC';
function Products() {
  const location = useLocation();
  const page = new URLSearchParams(location.search).get('page') || 1
  const limit = new URLSearchParams(location.search).get('size') || 15
  const inSearch = new URLSearchParams(location.search).get('in_search') || ''
  const popular = new URLSearchParams(location.search).get('popular') || ''


  return (
    <>
      <ProductsC page={page} limit={limit} inSearch={inSearch} popular={popular} />
    </>
  );
}

export default Products;
