import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Modal, Spinner } from 'react-bootstrap'
import './index.scss';
import { useImportFileMutation, useFetchStoreProductsCashedQuery } from '../../services/products';
import { useGetStoreQuery } from '../../services/store';
import { useSelector } from "react-redux";
import IconButton from '@mui/joy/IconButton';
import Button from '@mui/joy/Button';
import io from 'socket.io-client';
import PropTypes from 'prop-types';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import { CSVLink, CSVDownload } from 'react-csv';

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
};



const socket = io(process.env.REACT_APP_API_CONNECT);

function ProductsImport() {
  const { userId, userToken, userEmail, userName, status, admin } = useSelector((state) => state.auth);
  const location = useLocation();
  const { storeId } = useParams();
  const { register, handleSubmit, reset, formState: { errors } } = useForm();

  const storeProductsInfo = useFetchStoreProductsCashedQuery({ id: storeId, userId: userId, userToken: userToken, admin: admin }, { count: 1 }, { refetchOnMountOrArgChange: true })
  const storeProducts = storeProductsInfo?.data?.results;

  const storesInfo = useGetStoreQuery({ id: storeId, userId: userId, userToken: userToken, admin: admin }, { count: 1 }, { refetchOnMountOrArgChange: true })
  const store = storesInfo?.data?.store;

  const [updatefile, updatefileresult] = useImportFileMutation()
  const [getImportFile, setImportFile] = useState();
  const [getFileType, setFileType] = useState('');
  function importProductsFile() {
    updatefile({
      image_file: getImportFile,
      import_type: getFileType,
      userId: userId,
      userToken: userToken,
      userRole: admin,
      store_id: storeId,
    })
  }

  const [progress, setProgress] = useState({ progress: 0, type: '', in_progress: false });
  useEffect(() => {
    socket.on(`csv-file-uploading`, (data) => {
      setProgress(data?.results);
    });
    return () => {
      socket.off(`csv-file-uploading`);
    };
  }, []);

  const history = useHistory();
  const handleBack = () => {
    history.goBack();
  };

  function buttonDisabled() {
    updatefileresult.isLoading || getImportFile ? false : true
    if (updatefileresult.isLoading) {
      return true
    } else if (!getImportFile) {
      return true
    } else if (progress?.in_progress) {
      return true
    } else {
      return false
    }
  }

  const csvData = [
    ['title',
      'image_urls',
      'price_in_usd',
      'brand',
      'category',
      'weight_in_grams',
      'main_image',
      'country', 'uid',
      'in_stock',
      'condition',
      'delivery_days',
      'qty',
      'google_merchant',
      'features',
      'details',
      'variants',
      'current_variant',
      'specifications',
      'status'],
    [
      'product title',
      'https://microless.com/cdn/products/ac6258f5518377337ffa2c865096b921-md.jpg,https://microless.com/cdn/products/5a7b1eec58f28385835cd0b931e5ebc2-md.jpg',
      '200',
      'AMD',
      'laptop',
      '100',
      'https://prod-admin-images.s3.ap-south-1.amazonaws.com/WbjerLzUeW0cgVsOq5zQ/product/Ix1600.png',
      'AE',
      '119541',
      'In Stock',
      'New',
      2,
      10,
      0,
      ['features1', 'fetaures2'],
      'product deatils',
      `[{'129009': {'Generation:': '14th Gen', 'Series:': 'Core i7', 'Product Line Suffix:': 'K'}}, {'129006': {'Generation:': '14th Gen', 'Series:': 'Core i5', 'Product Line Suffix:': 'K'}}, {'129007': {'Generation:': '14th Gen', 'Series:': 'Core i9', 'Product Line Suffix:': 'KF'}}, {'112013': {'Generation:': '13th Gen', 'Series:': 'Core i7', 'Product Line Suffix:': 'K'}}]`,
      `{'Generation:': '14th Gen', 'Series:': 'Core i9', 'Product Line Suffix:': 'K'}`,
      `{'Brand': 'Intel', 'Model': 'i9-14900K Box', 'Microless SKU': '129005', 'Best Sellers Rank': '#2 inCPUs / Processors', 'Date first available': '18 October, 2023', 'Shipping Weight': '0.30 Kg', 'Shipping Dimensions': '6.50 cm x 15.00 cm x 16.51 cm'}`,
      0]
  ];

  const csvRefetchedData = [
    ['pid', 'price', 'currency', 'brand', 'country', 'in_stock', 'condition', 'google_merchant', 'status'],
    ['12345678', '99', 'USD', 'apple', 'US', '1', 'New', '1', '0']
  ];




  return (
    <div className="tabel_cards">
      <div className="row" style={{ margin: 0, padding: 0 }}>
        <div className="newFlexPagi">
          <div className="col-md-12">
            <div className="storeCardB">
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  overflow: 'auto',
                  width: '100%',
                  whiteSpace: 'nowrap'
                }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <IconButton size='sm' variant='soft' onClick={handleBack}><ArrowBackOutlinedIcon /></IconButton>
                  <div style={{ margin: '0px 10px' }}> Store Name: {store?.name}</div>
                </div>
                <div className="Order-Pagination">
                  Store Country: {store?.country}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <div className="row" style={{ margin: 0, padding: 0 }}>
        <div className="col-md-12" style={{ padding: 0 }}>
          <div className="row" style={{ margin: 0, padding: 0, justifyContent: 'center' }}>
            <div className="col-md-7" style={{ padding: 0 }}>
              <div style={{ background: '#f0f4f840', padding: '20px', border: '1px solid #eee', borderRadius: '10px' }}>
                <div className="row" style={{ justifyContent: 'center' }}>
                  <div className="col-md-12" style={{ textAlign: 'center' }}>
                    <div>
                      <div style={{ fontSize: '1rem', padding: '10px 0px 10px 0px', fontWeight: 500 }}>Import CSV for New Products or Refetched</div>
                      <div style={{ display: 'flex', textAlign: 'center', flexWrap: 'wrap', justifyContent: 'center', padding: '10px 0px 10px 0px' }}>
                        <CSVLink data={csvData} filename={"import-products-sample.csv"} target="_blank">
                          <Button
                            variant='plain'
                            size="sm"
                            style={{ width: 'auto', textTransform: 'capitalize', fontWeight: 400 }}
                          >
                            <small>Product CSV Sample</small>
                          </Button>
                        </CSVLink>
                        <CSVLink data={csvRefetchedData} filename={"import-refetched-products-sample.csv"} target="_blank">
                          <Button
                            variant='plain'
                            size="sm"
                            style={{ width: 'auto', textTransform: 'capitalize', fontWeight: 400 }}
                          >
                            <small>Refetch product CSV Sample</small>
                          </Button>
                        </CSVLink>
                      </div>
                      <div className="col-md-12">
                        {updatefileresult?.error?.data?.error &&
                          <div className='alert alert-danger' style={{ textAlign: 'center' }}>
                            {updatefileresult?.error?.data?.error}
                          </div>
                        }
                      </div>

                      {progress?.type &&
                        <div className="col-md-12" style={{ display: 'block' }}>
                          <Box sx={{ width: '100%' }}>
                            <LinearProgressWithLabel value={progress?.progress} />
                          </Box>
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div style={{ textTransform: 'capitalize', fontSize: '1rem', fontWeight: '600' }}>
                              {progress?.type}
                            </div>
                            {progress?.in_progress &&
                              <div style={{ margin: '0px 10px', display: 'flex', alignItems: 'center' }}>
                                <Spinner
                                  style={{ width: '1rem', height: '1rem', fontSize: '5px' }}
                                  animation="border"
                                />
                              </div>
                            }
                          </div>
                        </div>
                      }

                      <div className="row" style={{ justifyContent: 'center' }}>
                        <div className="col-md-11">
                          <div className="form-group">
                            <select className="form-control import-file-select" onChange={(e) => setFileType(e.target.value)}>
                              <option selected value={''}>Products CSV</option>
                              <option value={'refetch'}>Refetched products CSV</option>
                            </select>
                          </div>
                        </div>

                        <div className="col-md-11">
                          <div className="form-group">
                            <input multiple type="file" className="form-control import-file-csv"
                              onChange={(e) => setImportFile(e.target.files[0])} />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group" style={{ textAlign: 'center' }}>
                            <Button
                              variant="soft"
                              size="md"
                              style={{ fontWeight: 500, textTransform: 'capitalize' }}
                              disabled={buttonDisabled()}
                              onClick={importProductsFile}>
                              Update
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {storeProducts &&
                    <div className="col-md-12" style={{ display: 'block' }}>
                      <div style={{ fontSize: '1rem', fontWeight: '600', padding: '20px 0px 20px 0px', textAlign: 'center' }}>Last updated</div>
                      <div className="row" style={{justifyContent: 'center'}}>
                        <div className="col-md-11">
                          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingBottom: '5px' }}>
                            <div style={{ width: '100%' }}>File Name:</div>
                            <div style={{ width: '100%', textAlign: 'right' }}>{storeProducts?.file_name}</div>
                          </div>
                          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingBottom: '5px' }}>
                            <div style={{ width: '100%' }}>Progress:</div>
                            <div style={{ width: '100%', textAlign: 'right' }}>{storeProducts?.progress}%</div>
                          </div>
                          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingBottom: '5px' }}>
                            <div style={{ width: '100%' }}>Rows:</div>
                            <div style={{ width: '100%', textAlign: 'right' }}>{storeProducts?.limit}</div>
                          </div>
                          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingBottom: '5px' }}>
                            <div style={{ width: '100%' }}>Processed:</div>
                            <div style={{ width: '100%', textAlign: 'right' }}>{storeProducts?.processed}</div>
                          </div>
                          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingBottom: '5px' }}>
                            <div style={{ width: '100%' }}>Chunk Count:</div>
                            <div style={{ width: '100%', textAlign: 'right' }}>{storeProducts?.chunk_count}</div>
                          </div>
                          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingBottom: '5px' }}>
                            <div style={{ width: '100%' }}>Chunk Size:</div>
                            <div style={{ width: '100%', textAlign: 'right' }}>{storeProducts?.chunk_size}</div>
                          </div>
                        </div>

                      </div>
                      <br />
                    </div>
                  }

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
    </div>
  );
}

export default ProductsImport;
