import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useParams, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Modal } from 'react-bootstrap'
import './index.scss';
import "antd/dist/antd.min.css";
import { useGetAllUsersQuery, useCreateUserMutation, useUpdateUserMutation, useDeleteProductMutation } from '../../services/google_merchant';
import { useSelector } from "react-redux";
import IconButton from '@mui/joy/IconButton';
import Button from '@mui/joy/Button';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import LinearProgress from '@mui/material/LinearProgress';
import LanguageIcon from '@mui/icons-material/Language';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import SearchIcon from "@material-ui/icons/Search";
import PageError from '../PageError';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
function GoogleMerchant() {
  const { userId, userToken, userEmail, userName, status, admin } = useSelector((state) => state.auth);
  const location = useLocation();
  const history = useHistory();
  const { register, handleSubmit, reset, formState: { errors } } = useForm();

  const page = new URLSearchParams(location.search).get('page') || null
  const limit = new URLSearchParams(location.search).get('size') || 15

  const query = new URLSearchParams(location.search).get('query') || null
  const country = new URLSearchParams(location.search).get('country') || null

  const { merchant_id } = useParams();
  const productInfo = useGetAllUsersQuery({
    page: page,
    size: limit,
    merchantId: merchant_id,
    userId: userId,
    userToken: userToken,
    admin: admin,
    query: query,
    country: country
  }, { count: 1 }, { refetchOnMountOrArgChange: true })
  const products = productInfo?.data?.products;
  const pagination = productInfo?.data?.pagination;

  const [createNew, createresult] = useCreateUserMutation()
  const [deleteList, deleteresult] = useDeleteProductMutation()

  const [show, setShow] = useState(false);
  const [getEditShow, setEditShow] = useState(false);
  const [getTitle, setTitle] = useState("")
  const [getOfferId, setOfferId] = useState("")
  const [getCountry, setCountry] = useState("")
  const [getCondition, setCondition] = useState("")
  const [getPrice, setPrice] = useState(0)
  const [getImageUrls, setImageUrls] = useState("")
  const [getProductUrl, setProductUrl] = useState("")
  const [getCurrency, setCurrency] = useState("")
  const [getDescription, setDescription] = useState("")
  const [getAvailability, setAvailability] = useState("")
  const [getProductId, setProductId] = useState("")

  function handleCloseEdit() {
    setEditShow(false)
  }
  function handleOpenEdit(data) {
    setEditShow(true)
    setTitle(data.title)
    setOfferId(data.offerId)
    setCurrency(data.price?.currency)
    setDescription(data.description)
    setAvailability(data.availability)
    setProductUrl(data.link)
    setCountry(data.targetCountry)
    setCondition(data.condition)
    setPrice(data.price?.value)
    setImageUrls(data.imageLink)
    setProductId(data.id)
  }

  const [updatePost, updateresult] = useUpdateUserMutation()

  useEffect(() => {
    if (updateresult?.isSuccess) {
      setEditShow(false)
    }
    if (createresult?.isSuccess) {
      setShow(false)
    }
  }, [updateresult, createresult]);

  const updateData = {
    merchantId: merchant_id,
    offerId: getOfferId,
    productId: getProductId,
    title: getTitle,
    link: getProductUrl,
    imageLink: getImageUrls,
    targetCountry: getCountry,
    availability: getAvailability,
    condition: getCondition,
    price: getPrice,
    currency: getCurrency,
    userId: userId,
    userToken: userToken,
    admin: admin
  }

  useEffect(() => {
    if (createresult?.isSuccess) {
      reset()
    }
  }, [createresult]);


  function getLoadingProgress(thisLoading, thisFetching) {
    if (thisLoading) {
      return true
    } else if (thisFetching) {
      return true
    } else {
      return false
    }
  }
  const handleBack = () => {
    history.goBack();
  };

  function searchOrder(data) {
    const result = data?.orderId;
    const countryId = data?.countryId;
    history.push(`${window.location.pathname}?query=${result}&country=${countryId}`)
  }

  return (
    <>
      <div className="tabel_cards">

        <Modal className="new-post-modal" show={getEditShow} onHide={handleCloseEdit} centered animation={true}>
          <Modal.Body>
            <IconButton
              style={{ cursor: 'pointer', position: 'absolute', right: '10px', top: '10px', zIndex: 111 }}
              onClick={handleCloseEdit}
            >
              <CloseIcon className="Header-close-modal-icon" />
            </IconButton>
            <div className="row">
              <div className="col-md-12">
                <h5 style={{ textAlign: 'center', padding: '10px' }}>Update Product</h5>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-3">
                    <div className="form-group">
                      <label>Merchant</label>
                      <input readOnly type="text" className="form-control" defaultValue={5320178588} onChange={(e) => setMerchant(e.target.value)} />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label>ID</label>
                      <input readOnly type="text" className="form-control" defaultValue={getOfferId} onChange={(e) => setOfferId(e.target.value)} />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label>Price</label>
                      <input type="text" className="form-control" defaultValue={getPrice} onChange={(e) => setPrice(e.target.value)} />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label>Currency</label>
                      <input type="text" className="form-control" defaultValue={getCurrency} onChange={(e) => setCurrency(e.target.value)} />
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Title</label>
                      <input type="text" className="form-control" defaultValue={getTitle} onChange={(e) => setTitle(e.target.value)} />
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Image URL</label>
                      <input type="text" className="form-control" defaultValue={getImageUrls} onChange={(e) => setImageUrls(e.target.value)} />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label>Country</label>
                      <input type="text" className="form-control" defaultValue={getCountry} onChange={(e) => setCountry(e.target.value)} />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label>Availability</label>
                      <select className='form-control' defaultValue={getAvailability} onChange={(e) => setAvailability(e.target.value)}>
                        <option value={'In Stock'} selected>In Stock</option>
                        <option value={'Out of Stock'}>Out of Stock</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label>Condition</label>
                      <input type="text" className="form-control" defaultValue={getCondition} onChange={(e) => setCondition(e.target.value)} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group" style={{ justifyContent: 'flex-end', display: 'flex' }}>
                      <Button disabled={updateresult?.isLoading ? true : false}
                        variant="soft"
                        size="sm"
                        style={{ fontWeight: 400 }}
                        onClick={() => { updatePost(updateData) }}
                      >
                        {updateresult?.isLoading ? 'Loading...' : 'Update'}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>


        <div className="row" style={{ margin: 0, padding: 0 }}>
          <div className="newFlexPagi">
            <div className="col-md-12">
              <div className="storeCardB">

                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <IconButton variant='soft' size='sm' style={{ height: '35px' }} onClick={handleBack}><ArrowBackOutlinedIcon /></IconButton>
                  <div style={{ position: 'relative', marginRight: '10px' }}>
                    <form onSubmit={handleSubmit(searchOrder)} className="table_search_card">
                      <input
                        style={{ margin: '0px 5px', height: '35px' }}
                        defaultValue={query}
                        type="text"
                        className="form-control"
                        placeholder="AE"
                        id="countryId"
                        aria-invalid={errors.countryId ? "true" : "false"}
                        {...register("countryId", { required: true })}
                      />
                      <input
                        style={{ height: '35px' }}
                        defaultValue={query}
                        type="text"
                        className="form-control table_search"
                        placeholder="Product ID..."
                        id="orderId"
                        aria-invalid={errors.orderId ? "true" : "false"}
                        {...register("orderId", { required: true })}
                      />
                      <IconButton size='sm' style={{ top: '1.5px', right: '2px', borderRadius: '100px' }} aria-haspopup="true" type="submit" className="table_search_icon">
                        <SearchIcon style={{ fontSize: 'inherit' }} />
                      </IconButton>
                    </form>
                  </div>
                  <IconButton
                    onClick={(() => history.push(`${window.location.pathname}`))}
                    variant='soft'
                    size='sm'
                  >
                    <RestartAltIcon />
                  </IconButton>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row" style={{ margin: 0, padding: 0 }}>
          <div className="col-md-12" style={{ padding: 0 }}>
            <div className="storeCardB">
              <div className="updateButton">
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', overflow: 'auto', width: '100%', whiteSpace: 'nowrap' }}>

                  <div className="Order-Pagination">
                    <Button
                      style={{ margin: '0px 10px', fontWeight: 400 }}
                      disabled={productInfo?.isLoading || !page}
                      variant="soft"
                      size="sm"
                      component={Link}
                      to={{
                        pathname:
                          `${window.location.pathname}`,
                        search: page && ``
                      }}
                    >
                      {productInfo?.isLoading ?
                        <span>Loadind...</span> :
                        <span> First </span>}
                    </Button>

                    <Button
                      style={{ fontWeight: 400 }}
                      disabled={productInfo?.isLoading}
                      variant="soft"
                      size="sm"
                      component={Link}
                      to={{
                        pathname:
                          `${window.location.pathname}`,
                        search: pagination && `?page=${pagination}`
                      }}
                    >
                      {productInfo?.isLoading ?
                        <span>Loadind...</span> :
                        <span>Next</span>}
                    </Button>
                  </div>
                </div>

                <div className="table_search_card">
                  <div>
                    <Button
                      style={{ fontWeight: 400 }}
                      disabled={productInfo?.isLoading}
                      variant="soft"
                      size="sm"
                      component={Link}
                      to={`/google-merchant/action/${merchant_id}`}
                    >
                      Action
                    </Button>
                  </div>
                  <div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row" style={{ margin: 0, padding: 0 }}>
          <div className="col-md-12" style={{ padding: 0 }}>
            <div className="tabelOverflow" style={{ position: 'relative' }}>
              {getLoadingProgress(productInfo?.isLoading, productInfo?.isFetching) &&
                <div className='loading-progress-bg' style={{ position: 'absolute', top: 0, right: 0, left: 0, zIndex: 222, height: '100%', background: '#ffffff82' }}>
                  <LinearProgress style={{ height: '2px' }} />
                </div>
              }
              <table className="table">
                <thead>
                  <tr>
                    <th style={{ width: '0px' }}>ID</th>
                    <th style={{ textAlign: 'center' }}>Image</th>
                    <th>Title</th>
                    <th>Price</th>
                    <th>Country</th>
                    <th>Availability</th>
                    <th>Condition</th>
                    <th className="sticky-column tar" style={{ textAlign: 'right', width: '1px' }}>Manage</th>
                  </tr>
                </thead>

                <tbody style={{ position: 'relative' }}>
                  {products?.map((item, i) =>
                    <tr key={i}>
                      <td>{item.offerId}</td>
                      <td style={{ textAlign: 'center' }}>
                        <img src={`${item.imageLink}`} style={{ width: '35px', height: '35px', objectFit: 'contain' }} />
                      </td>
                      <td>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <IconButton
                            onClick={() => window.open(`${item.link}`, '_blank').focus()}
                          >
                            <LanguageIcon style={{ fontSize: '12px' }} />
                          </IconButton>
                          <IconButton
                            onClick={() => handleOpenEdit(item)}
                          >
                            <EditIcon style={{ fontSize: '12px' }} />
                          </IconButton>
                          <Link to={`/product/${item.offerId}`} style={{ whiteSpace: 'initial' }}>
                            {item.title}
                          </Link>
                        </div>
                      </td>
                      <td>{item.price?.currency} {item.price?.value}</td>
                      <td style={{ whiteSpace: 'initial' }}>{item.targetCountry}</td>
                      <td style={{ whiteSpace: 'initial' }}>{item.availability}</td>
                      <td style={{ whiteSpace: 'initial' }}>{item.condition}</td>

                      <td className="sticky-column">
                        <div className="dFlex center nowrap jcr">
                          <Button
                            onClick={() => deleteList({
                              merchantId: merchant_id,
                              id: item.offerId,
                              product_id: item.id,
                              userId: userId,
                              userToken: userToken,
                              admin: admin
                            })}
                            color={`danger`}
                            variant={`soft`}
                            size="sm"
                            disabled={deleteresult?.isLoading && deleteresult?.originalArgs?.id === item.offerId ? true : false}
                          >
                            <small className="dFlex">{deleteresult?.isLoading && deleteresult?.originalArgs?.id === item.offerId ? `Deleting...` : `Delete`}</small>
                          </Button>
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            {products?.length === 0 &&
              <div style={{ padding: '20px', textAlign: 'center' }}>Not Data Found!</div>
            }
          </div>
        </div>
        <br /><br />
      </div>
      <PageError loading={productInfo?.isLoading} originalStatus={productInfo?.error?.originalStatus} data={productInfo?.error?.data} />
    </>
  );
}

export default GoogleMerchant;
