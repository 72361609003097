import React, { useState, useEffect, Fragment } from 'react';
import CloseIcon from "@material-ui/icons/Close";
import Button from '@mui/joy/Button';
import IconButton from '@mui/material/IconButton';
import Loader from '../../Helper/Loader';

function ImageUpdate({ setUpdateImage, getUpdateImage, imageresult, updateBookImage, setUploadingStatus }) {

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <div className="form-group">
            <input
              required
              style={{ height: 'auto' }}
              multiple
              type="file"
              className="form-control"
              onChange={(e) => setUpdateImage(e.target.files)}
            />
          </div>
        </div>
        <div className="col-md-12">
          <div className="form-group" style={{ textAlign: 'center' }}>
            {imageresult.isLoading ?
              <> <Loader /> <br />
                <h5>uploading... don't close or reload the browser </h5>
              </>
              :
              <Button
                style={{ width: '100%' }}
                disabled={imageresult.isLoading || !getUpdateImage}
                variant="soft"
                size="md"
                onClick={(() => updateBookImage())}>
                Update
              </Button>
            }
          </div>
        </div>
      </div>
    </>
  );
}

export default ImageUpdate;
