import React from 'react';
import { useLocation } from "react-router-dom";
import OrderItemsC from '../../components/OrderItemsC';

function OrderItems() {
  const location = useLocation();
  const page = new URLSearchParams(location.search).get('page') || 1
  const limit = new URLSearchParams(location.search).get('size') || 15
  const shippingStatus = new URLSearchParams(location.search).get('shipping_status') || null
  const thisShippingStatus = shippingStatus ? `&shipping_status=${shippingStatus}` : ''
  const filter = new URLSearchParams(location.search).get('filter') || null
  const thisFilter = filter ? `&filter=${filter}` : ''
  const query = new URLSearchParams(location.search).get('query') || null
  const thisQuery = query ? `&query=${query}` : ''
  const assignee = new URLSearchParams(location.search).get('assignee') || null
  const thisAssignee = assignee ? `&assignee=${assignee}` : ''
  const ordered = new URLSearchParams(location.search).get('ordered') || ''
  const thisOrdered = ordered ? `&ordered=${ordered}` : ''
  const paymentStatus = new URLSearchParams(location.search).get('payment_status') || 'true'
  const thisPaymentStatus = paymentStatus ? `&payment_status=${paymentStatus}` : ''

  const dateRange = new URLSearchParams(location.search).get('date_range') || null
  const thisDateRange = dateRange ? `&date_range=${dateRange}` : ''

  return (
    <>
      <OrderItemsC
        page={page}
        limit={limit}
        shippingStatus={shippingStatus}
        thisShippingStatus={thisShippingStatus}
        filter={filter}
        thisFilter={thisFilter}
        query={query}
        thisQuery={thisQuery}
        assignee={assignee}
        thisAssignee={thisAssignee}
        ordered={ordered}
        thisOrdered={thisOrdered}
        paymentStatus={paymentStatus}
        thisPaymentStatus={thisPaymentStatus}
        thisDateRange={thisDateRange}
      />

    </>
  );
}

export default OrderItems;
