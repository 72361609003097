import React, { useState } from 'react';
import { Link, useHistory, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Modal } from 'react-bootstrap'
import './index.scss';
import { useFetchQuery, useUpdateStatesMutation } from '../../services/orderItems';
import { useSelector } from "react-redux";
import moment from 'moment'
import IconButton from '@mui/joy/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import { Tag, Menu as Menus } from 'antd';
import LanguageIcon from '@mui/icons-material/Language';
import LinearProgress from '@mui/material/LinearProgress';
import SearchIcon from "@material-ui/icons/Search";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Dropdown from '@mui/joy/Dropdown';
import Menu from '@mui/joy/Menu';
import MenuButton from '@mui/joy/MenuButton';
import MenuItem from '@mui/joy/MenuItem';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import OrderItemsList from './OrderItemsList';
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
import { DatePicker, Radio } from 'antd';
const { RangePicker } = DatePicker;


const userEmailIds = ['ali.akbar@ebtida.com', 'fahd@ebtida.com', 'hesham@ebtida.com', 'salman@ebtida.com', 'faiz@ebtida.com', 'hamza@ebtida.com', 'jauhar@ebtida.com', 'anas@ebtida.com']
const shippingStatusList = ['processing', 'procured', 'shipping', 'out_for_delivery', 'delivered', 'cancelled']
const tagsStatusList = ['delayed', 'local', 'international']
const statusList = [
  {
    name: 'Checkout Success',
    value: 'true'
  },
  {
    name: 'Checkout Failed',
    value: 'false'
  }
]

function OrderItemsC({ page, limit, shippingStatus, thisShippingStatus, filter, thisFilter, query, thisQuery, assignee, thisAssignee, ordered, thisOrdered, paymentStatus, thisPaymentStatus, thisDateRange }) {
  const { userId, userToken, userEmail, userName, status, admin } = useSelector((state) => state.auth);
  const location = useLocation();
  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const [getSearchQuery, searchQuery] = useState("")

  const [updatestates, updatestatesresults] = useUpdateStatesMutation()

  const history = useHistory();
  const { data: ordersData, refetch, isLoading, isFetching, error } = useFetchQuery({
    page: page,
    size: limit,
    userId: userId,
    userToken: userToken,
    admin: admin,
    shipping_status: shippingStatus,
    filter: filter,
    query: query,
    userEmail: userEmail,
    assignee: assignee,
    ordered: ordered,
    payment_status: paymentStatus,
    date_range: thisDateRange
  }, { count: 1 }, { refetchOnMountOrArgChange: true })
  const orders = ordersData?.entities?.undefined?.order_items;
  const pagination = ordersData?.entities?.undefined?.pagination;

  // const stockTimerId = useRef();
  // React.useEffect(() => {
  //   stockTimerId.current = setInterval(() => {
  //     refetch()
  //   }, 420000);
  //   return () => {
  //     clearInterval(stockTimerId.current);
  //   };
  // }, []);

  function refetchOrderItems() {
    refetch()
    return;
  }

  const [getOrderId, setOrderId] = useState('');
  const [getEditShow, setEditShow] = useState(false);
  const [getOrderItemsData, setOrderItemsData] = useState([]);
  function handleCloseEdit() {
    setEditShow(false)
  }

  function getDeliverydays(data, created) {
    function getTimeline() {
      if (Number(data) === 1) {
        return 'Tomorrow'
      } else {
        return ''
      }
    }
    if (Number(data) > 0) {
      return <><strong>{getTimeline()}</strong> {moment(created).add(Number(data), 'days').format('dddd Do MMM YYYY')}</>
    } else {
      return moment(created).add(10, 'days').format('dddd Do MMM YYYY')
    }
  }

  function functionIdLink(id, pid, store_id) {
    if (Number(store_id) === 3) {
      return pid
    } else {
      return id
    }
  }

  function oiBgColor(data) {
    if (data === 'accepted') {
      return '#008001'
    } else if (data === 'cancelled') {
      return '#cd201f'
    } else {
      return '#ffa500f5'
    }
  }

  function getLoadingProgress(thisLoading, thisFetching) {
    if (thisLoading) {
      return true
    } else if (thisFetching) {
      return true
    } else {
      return false
    }
  }

  function searchOrder(data) {
    const result = data?.orderId;
    history.push(`/items?query=${result}`)
  }

  function clearSearch() {
    reset()
    history.push(`${window.location.pathname}`)
  }


  const onRangeChange = (date, dateString) => {
    history.push(`${window.location.pathname}?page=${1}&date_range=${dateString}`)
  };


  return (
    <>
      <div className="tabel_cards">

        <Modal className="view-items-modal" show={getEditShow} onHide={handleCloseEdit} centered animation={true}>
          <Modal.Body>
            <IconButton
              style={{ cursor: 'pointer', position: 'absolute', right: '5px', top: '5px', zIndex: 111 }}
              onClick={handleCloseEdit}
            >
              <CloseIcon className="Header-close-modal-icon" />
            </IconButton>
            <div className="row">
              <div className="col-md-12">
                <h5 style={{ textAlign: 'center', padding: '20px' }}>Order Items</h5>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="tabelOverflow" style={{ padding: '20px' }}>
                  {getOrderItemsData?.order_items?.map((item, i) =>
                    <div
                      key={i}
                      style={{
                        marginBottom: '10px',
                        border: '1px solid #eee',
                        paddingBottom: '10px',
                        position: 'relative',
                        background: '#fff',
                        padding: '10px'
                      }}>
                      <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                        <div style={{ margin: '0 5px', border: '1px solid #eee', background: '#fff', padding: '5px', borderRadius: '4px' }}>
                          <img src={`${item.image}`} style={{ width: '70px', height: '70px', objectFit: 'contain' }} />
                        </div>
                        <div style={{ margin: '0 10px', marginRight: '20px', width: '100%' }}>
                          <div style={{ fontSize: '12px', lineHeight: 'normal' }}>{item.title}</div>
                          <div style={{ display: 'flex', alignItems: 'center', padding: '5px 0px 5px 0px', justifyContent: 'space-between' }}>
                            <div> {item.currency}  {item.price}</div>
                            <Tag
                              color={oiBgColor(item.state)}
                              style={{ fontWeight: 500 }}>
                              <span style={{ textTransform: 'capitalize' }}>{item.state}</span>
                            </Tag>
                          </div>
                          {item.current_variant &&
                            <div style={{ padding: '5px 0px 5px 0px' }}>
                              {Object.entries(item.current_variant || []).map(([key, value], index) => (
                                <Tag style={{ fontSize: '10px' }} key={index}><strong>{key}:</strong> {value}</Tag>
                              ))}
                            </div>
                          }
                          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '5px 0px 5px 0px', whiteSpace: 'nowrap', overflow: 'auto' }}>
                            <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                              <Tag color='default'>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                  <img
                                    style={{ margin: "0 4px" }}
                                    loading="lazy"
                                    width="20"
                                    src={`https://flagcdn.com/w20/${item.country?.toLowerCase()}.png`}
                                    srcSet={`https://flagcdn.com/w40/${item.country?.toLowerCase()}.png 2x`}
                                    alt=""
                                  />
                                  <span style={{ margin: '0px 5px' }}>{item.country} </span>
                                </div>
                              </Tag>
                              <Tag color='default'><strong>ID </strong><span> {item.id} </span></Tag>
                              {Number(item.store_id) === 3 &&
                                <Tag color='default'><strong>Asin </strong><span> {item.asin} </span></Tag>
                              }
                              <Tag color='default'><strong>PID: </strong><span> {functionIdLink(item.product_id, item.pid, item.store_id)} </span></Tag>
                              <Tag color='default'><strong>QTY.: </strong><span> {item.quantity} </span></Tag>
                              <a href={`https://www.thwifty.com/product/${item.store_id}/${functionIdLink(item.product_id, item.pid, item.store_id)}`} target='_blank'>
                                <IconButton>
                                  <LanguageIcon style={{ fontSize: '1rem' }} />
                                </IconButton>
                              </a>
                            </div>
                          </div>
                          <div style={{ fontSize: '12px' }}>Delivery by {getDeliverydays(item.estimated_delivery, item.createdAt)}</div>
                          {item.note &&
                            <Tag color='volcano' style={{ fontSize: '12px', color: '#d3302f', margin: '0px' }}>{item.note}</Tag>
                          }
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <div className="row sticky-pg" style={{ margin: 0, padding: 0 }}>
          <div className="newFlexPagi">
            <div className="col-md-12">
              <div>
                <div className="storeCardB">
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', overflow: 'auto', width: '100%', whiteSpace: 'nowrap', flexWrap: 'wrap' }}>
                    <div>Showing {pagination?.totalCounts && pagination?.totalCounts} entries</div>
                    <div className="Order-Pagination">
                      <Pagination
                        style={{ width: '100%', textAlign: 'right', display: 'flex', justifyContent: 'flex-end', padding: '0px 0px 0px 0px' }}
                        page={parseInt(page)}
                        count={parseInt(pagination?.totalPages)}
                        color="primary"
                        variant="outlined"
                        renderItem={(item) => (
                          <PaginationItem
                            className='list-paginatiion-item'
                            component={Link}
                            to={{
                              pathname: `${window.location.pathname}`,
                              search: `?page=${parseInt(item.page)}${thisFilter}${thisShippingStatus}${thisAssignee}${thisOrdered}${thisQuery}${thisDateRange}`
                            }}
                            {...item}
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row" style={{ margin: 0, padding: 0 }}>
          <div className="col-md-12" style={{ padding: 0 }}>
            <div className="storeCardB">
              <div className="updateButton">
                <div className='overflow' style={{ display: 'flex', alignItems: 'center' }}>
                  <div style={{ position: 'relative' }}>
                    <form onSubmit={handleSubmit(searchOrder)} className="table_search_card">
                      <input
                        defaultValue={query}
                        type="text"
                        className="form-control table_search"
                        placeholder="Search for order..."
                        id="orderId"
                        aria-invalid={errors.orderId ? "true" : "false"}
                        {...register("orderId", { required: true })}
                      />
                      {!query &&
                        <IconButton size='sm' style={{ top: '3px', right: '5px', borderRadius: '100px' }} aria-haspopup="true" type="submit" className="table_search_icon">
                          <SearchIcon style={{ fontSize: 'inherit' }} />
                        </IconButton>
                      }
                      {query &&
                        <IconButton
                          size='sm'
                          style={{ top: '3px', right: '5px', borderRadius: '100px' }}
                          className="table_search_icon"
                          onClick={(() => clearSearch())}
                        >
                          <CloseIcon style={{ fontSize: 'inherit' }} />
                        </IconButton>}
                    </form>
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center', margin: '0px 10px' }}>
                    <div style={{ position: 'relative' }}>
                      <IconButton
                        onClick={(() => refetchOrderItems())}
                      >
                        <RefreshOutlinedIcon />
                      </IconButton>
                    </div>
                  </div>


                  <div style={{ display: 'flex', alignItems: 'center', margin: '0px 10px' }}>
                    <div style={{ position: 'relative' }}>
                      <Dropdown>
                        <MenuButton
                          style={{ padding: '0px 7px 0px 7px', borderRadius: '6px', fontWeight: 400, fontSize: '12px' }}
                          className="Header-button-connect">
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <span style={{ textTransform: 'capitalize' }}>
                              Payment:&nbsp; <strong>{paymentStatus === 'true' ? 'Success' : 'Failed'}</strong></span>
                            <ArrowDropDownIcon style={{ fontSize: '1.5rem', margin: '0 3px' }} />
                          </div>
                        </MenuButton>
                        <Menu>
                          {statusList?.map((name, i) =>
                            <MenuItem
                              key={i}
                              className='orders-sub-side-menu'
                              component={Link}
                              to={`${window.location.pathname}${`?page=${1}${paymentStatus === name.value ? `` : `&payment_status=${name.value}`}`}${thisShippingStatus}${thisAssignee}${thisOrdered}`}
                            >
                              <div style={{ display: 'flex', alignItems: 'center', fontSize: '14px' }}>
                                <input style={{ margin: '0px 5px' }} type='checkbox' checked={paymentStatus === name.value ? true : false} />
                                <div style={{ textAlign: 'left', textTransform: 'capitalize' }}>{name.name}</div>
                              </div>
                            </MenuItem>

                          )}
                        </Menu>
                      </Dropdown>
                    </div>
                  </div>


                  <div style={{ display: 'flex', alignItems: 'center', margin: '0px 10px' }}>
                    <div style={{ position: 'relative' }}>
                      <Dropdown>
                        <MenuButton
                          style={{ padding: '0px 7px 0px 7px', borderRadius: '6px', fontWeight: 400, fontSize: '12px' }}
                          className="Header-button-connect">
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <span style={{ textTransform: 'capitalize' }}>Filter:&nbsp; <strong>{filter?.replace(/_/g, ' ')}</strong></span>
                            <ArrowDropDownIcon style={{ fontSize: '1.5rem', margin: '0 3px' }} />
                          </div>
                        </MenuButton>
                        <Menu>
                          {tagsStatusList?.map((name, i) =>
                            <MenuItem
                              key={i}
                              className='orders-sub-side-menu'
                              component={Link}
                              to={`${window.location.pathname}${`?page=${1}${filter === name ? `` : `&filter=${name}`}`}${thisShippingStatus}${thisAssignee}${thisOrdered}${thisPaymentStatus}`}
                            >
                              <div style={{ display: 'flex', alignItems: 'center', fontSize: '14px' }}>
                                <input style={{ margin: '0px 5px' }} type='checkbox' checked={filter === name ? true : false} />
                                <div style={{ textAlign: 'left', textTransform: 'capitalize' }}>
                                  {name?.replace(/_/g, ' ')}
                                </div>
                              </div>
                            </MenuItem>
                          )}
                        </Menu>
                      </Dropdown>
                    </div>
                  </div>


                  <div style={{ display: 'flex', alignItems: 'center', margin: '0px 10px' }}>
                    <div style={{ position: 'relative' }}>
                      <Dropdown>
                        <MenuButton
                          style={{ padding: '0px 7px 0px 7px', borderRadius: '6px', fontWeight: 400, fontSize: '12px' }}
                          className="Header-button-connect">
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <span style={{ textTransform: 'capitalize' }}>Shipping Status:&nbsp; <strong>{shippingStatus?.replace(/_/g, ' ')}</strong></span>
                            <ArrowDropDownIcon style={{ fontSize: '1.5rem', margin: '0 3px' }} />
                          </div>
                        </MenuButton>
                        <Menu>
                          <MenuItem
                            className='orders-sub-side-menu'
                            disabled={shippingStatus === null}
                            component={Link}
                            to={`${window.location.pathname}${`?page=${1}`}${thisFilter}${thisAssignee}${thisOrdered}${thisPaymentStatus}`}
                          >
                            <div style={{ display: 'flex', alignItems: 'center', fontSize: '14px' }}>
                              <input style={{ margin: '0px 5px' }} type='checkbox' checked={shippingStatus === null ? true : false} />
                              <div style={{ textAlign: 'left', textTransform: 'capitalize' }}>All</div>
                            </div>
                          </MenuItem>
                          {shippingStatusList?.map((name, i) =>
                            <MenuItem
                              key={i}
                              className='orders-sub-side-menu'
                              component={Link}
                              to={`${window.location.pathname}${`?page=${1}${shippingStatus === name ? `` : `&shipping_status=${name}`}`}${thisFilter}${thisAssignee}${thisOrdered}${thisPaymentStatus}`}
                            >
                              <div style={{ display: 'flex', alignItems: 'center', fontSize: '14px' }}>
                                <input style={{ margin: '0px 5px' }} type='checkbox' checked={shippingStatus === name ? true : false} />
                                <div style={{ textAlign: 'left', textTransform: 'capitalize' }}>{name?.replace(/_/g, ' ')}</div>
                              </div>
                            </MenuItem>

                          )}
                        </Menu>
                      </Dropdown>
                    </div>
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center', margin: '0px 10px' }}>
                    <div style={{ position: 'relative' }}>
                      <Dropdown>
                        <MenuButton
                          style={{ padding: '0px 7px 0px 7px', borderRadius: '6px', fontWeight: 400, fontSize: '12px' }}
                          className="Header-button-connect">
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <span style={{ textTransform: 'capitalize' }}>Assignee:&nbsp; <strong>{assignee}</strong></span>
                            <ArrowDropDownIcon style={{ fontSize: '1.5rem', margin: '0 3px' }} />
                          </div>
                        </MenuButton>
                        <Menu>
                          {userEmailIds?.map((email, i) =>
                            <MenuItem
                              key={i}
                              className='orders-sub-side-menu'
                              component={Link}
                              to={`${window.location.pathname}${`?page=${1}${assignee === email ? `` : `&assignee=${email}`}`}${thisFilter}${thisShippingStatus}${thisOrdered}${thisPaymentStatus}`}
                            >
                              <div style={{ display: 'flex', alignItems: 'center', fontSize: '14px' }}>
                                <input style={{ margin: '0px 5px' }} type='checkbox' checked={assignee === email ? true : false} />
                                <div style={{ textAlign: 'left' }}>{email}</div>
                              </div>
                            </MenuItem>
                          )}
                        </Menu>
                      </Dropdown>
                    </div>
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center', margin: '0px 10px' }}>
                    <div style={{ position: 'relative' }}>
                      {/* <RangePicker className='date-range-style' onChange={onRangeChange} /> */}
                      <DatePicker onChange={onRangeChange} />
                    </div>
                  </div>
                </div>
                <div className="table_search_card">

                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row" style={{ margin: 0, padding: 0, position: 'relative' }}>
          <div style={{ padding: 0, width: '100%' }}>
            <div className="tabelOverflow" style={{ position: 'relative' }}>
              {getLoadingProgress(isLoading, isFetching) &&
                <div className='loading-progress-bg' style={{ position: 'absolute', top: 0, right: 0, left: 0, zIndex: 222, height: '100%', background: '#ffffff82' }}>
                  <LinearProgress style={{ height: '2px' }} />
                </div>
              }
              <table className="table" style={{ margin: 0 }}>
                <thead>
                  <tr style={{ position: 'relative' }}>
                    <th>
                      #
                    </th>
                    <th>Order</th>
                    <th>Image</th>
                    <th>Title</th>
                    <th>Price</th>
                    <th>Shipping To</th>
                    <th>Domain</th>
                    <th className="sticky-column" style={{ textAlign: 'right', position: 'relative' }}>
                      <IconButton
                        variant='outlined'
                        size="sm"
                        style={{ margin: '0px 10px', padding: '0px', position: 'absolute', left: 0, top: 0, display: 'flex', alignItems: 'center', zIndex: 1, height: '100%', borderTop: 'none', borderBottom: 'none', borderRadius: 0 }}
                        component={Link}
                        to={`${window.location.pathname}${`?page=${1}${`&ordered=${!ordered ? 'ASC' : ordered === 'DESC' ? 'ASC' : 'DESC'}`}`}${filter ? `&filter=${filter}` : ''}${shippingStatus ? `&shipping_status=${shippingStatus}` : ''}${assignee ? `&assignee=${assignee}` : ''}`}
                      >
                        <ImportExportIcon style={{ fontSize: '1.2rem', color: ordered === 'ASC' ? '#fe7f4a' : '' }} />
                      </IconButton>
                      Placed On
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <OrderItemsList ordersData={ordersData} updatestatesresults={updatestatesresults} updatestates={updatestates} />
                </tbody>
              </table>
            </div>
            {orders?.length === 0 &&
              <h5 className="data_not-found">No data available in table</h5>
            }
            {error?.originalStatus === 403 &&
              <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                <div className='alert alert-danger' style={{ background: '#fff0', border: 'none' }}>{error?.data}</div>
              </div>
            }
          </div>
        </div>
        <br /><br />

      </div>
    </>
  );
}

export default OrderItemsC;
