import React, { useState, useEffect, Fragment } from 'react';
import Divider from '@mui/material/Divider';
import Dropdown from '@mui/joy/Dropdown';
import Menu from '@mui/joy/Menu';
import MenuButton from '@mui/joy/MenuButton';
import MenuItem from '@mui/joy/MenuItem';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

function ViewManifest({ groupItems, viewmanifest, userId, userToken, orderData }) {

  return (
    <>
      {groupItems?.[0].awb_number &&
        <>

          <div style={{ display: 'flex', alignItems: 'center', margin: '0px 10px' }}>
            <div style={{ fontSize: '14px' }}>
              <strong>AWB:</strong> &nbsp;{groupItems?.[0].awb_number}
            </div>
          </div>

          <Dropdown>
            <MenuButton
              className='m-margin-0'
              style={{ padding: '0px 8px', minHeight: '25px', fontWeight: 400 }}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <ArrowDropDownIcon style={{ fontSize: '1.5rem', margin: '0 3px' }} />
              </div>
            </MenuButton>
            <Menu className='listUpdate-card'>
              <MenuItem
                onClick={(() => viewmanifest({
                  awb_number: groupItems?.[0].awb_number,
                  userId: userId,
                  userToken: userToken,
                  name: 'flipexp',
                  view_manifest: true
                }))}
              >
                <span>View Manifest</span>
              </MenuItem>
              <MenuItem
                onClick={(() => viewmanifest({
                  awb_number: groupItems?.[0].awb_number,
                  userId: userId,
                  userToken: userToken,
                  name: 'flipexp',
                  track_shipment: true
                }))}
              >
                <span>Track Shipment</span>
              </MenuItem>
              <MenuItem
                onClick={(() => viewmanifest({
                  awb_number: groupItems?.[0].awb_number,
                  userId: userId,
                  userToken: userToken,
                  name: 'flipexp',
                  view_manifest: true,
                  pdf: 'pdf'
                }))}
              >
                <span>Print pdf</span>
              </MenuItem>
              <MenuItem
                onClick={(() => viewmanifest({
                  awb_number: groupItems?.[0].awb_number,
                  userId: userId,
                  userToken: userToken,
                  name: 'flipexp',
                  view_manifest: true,
                  pdf: 'pdf46'
                }))}
              >
                <span>Print pdf_4x6</span>
              </MenuItem>

              <Divider />
              {groupItems?.[0].package_weight &&
                <MenuItem disabled>
                  <div style={{ fontSize: '1rem', textTransform: 'capitalize' }}>Package Weight</div>
                  <div style={{ fontSize: '1rem', textTransform: 'capitalize' }}>{Number(groupItems?.[0].package_weight) / 1000}kg</div>
                </MenuItem>
              }
              {groupItems?.[0].customs_value &&
                <MenuItem disabled>
                  <div style={{ fontSize: '1rem', textTransform: 'capitalize' }}>Package Customs Value</div>
                  <div style={{ fontSize: '1rem', textTransform: 'capitalize' }}>{orderData?.currency} {groupItems?.[0].customs_value}</div>
                </MenuItem>
              }

            </Menu>
          </Dropdown>
        </>
      }
    </>
  )

}

export default ViewManifest;
