import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import './index.scss';
import SearchIcon from "@material-ui/icons/Search";
import "antd/dist/antd.min.css";
import { useSelector } from 'react-redux'
import { useShipmentsQuery } from '../../services/orderItems';
import Moment from 'moment';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/joy/IconButton';
import LinearProgress from '@mui/material/LinearProgress';

function Shipments() {
  const { userId, userToken, userEmail, userName, isStatus, userRole, languageCode, isAdmin, accessKey } = useSelector((state) => state.auth);
  const history = useHistory();
  const { register, handleSubmit, formState: { errors }, reset } = useForm();

  const { register: register2, handleSubmit: handleSubmit2, formState: { errors: errors2 }, reset: reset2 } = useForm();

  const location = useLocation();
  const page = new URLSearchParams(location.search).get('page') || 1
  const limit = new URLSearchParams(location.search).get('size') || 15

  const search = new URLSearchParams(location.search).get('search') || null
  const thisSearch = search ? `&search=${search}` : ''

  const shipmentsInfo = useShipmentsQuery({
    page: page,
    size: limit,
    search: search,
    userId: userId,
    userToken: userToken,
    userRole: userRole
  }, { count: 1 }, { refetchOnMountOrArgChange: true })
  const shipments = shipmentsInfo?.data?.shipments;
  const pagination = shipmentsInfo?.data?.pagination;


  function getLoadingProgress(thisLoading, thisFetching) {
    if (thisLoading) {
      return true
    } else if (thisFetching) {
      return true
    } else {
      return false
    }
  }

  function searchOrder(data) {
    const result = data?.orderId;
    history.push(`${window.location.pathname}?search=${result}`)
  }

  function clearSearch() {
    reset2()
    history.push(`${window.location.pathname}`)
  }

  function shippingStatusC(data) {

    function getthisplit(thisData) {
      const values = thisData?.split('_');
      if (values?.includes('arrived_')) {
        return 'shipping'
      } else if (values?.includes('departed')) {
        return 'shipping'
      } else if (values?.includes('dispatched')) {
        return 'procured'
      } else {
        return thisData
      }
    }
    let color
    if (getthisplit(data) === 'delivered') {
      color = 'badge badge-success'
    } else if (getthisplit(data) === 'cancel') {
      color = 'badge badge-danger'
    } else if (getthisplit(data) === 'processing') {
      color = 'badge badge-info'
    } else {
      color = 'badge badge-warning'
    }
    return { name: getthisplit(data)?.replace(/_/g, ' '), color: color }
  }

  return (
    <>
      <div className="tabel_cards">


        <div className="row sticky-pg" style={{ margin: 0, padding: 0 }}>
          <div className="newFlexPagi">
            <div className="col-md-12">
              <div className="storeCardB">
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', overflow: 'auto', width: '100%', whiteSpace: 'nowrap' }}>
                  <div>Showing {pagination?.totalCounts && pagination?.totalCounts} entries</div>
                  <div className="Order-Pagination">

                    <Pagination
                      className='list-paginatiion-table'
                      style={{ width: '100%', textAlign: 'right', display: 'flex', justifyContent: 'flex-end', padding: '0px' }}
                      page={parseInt(page)}
                      count={parseInt(pagination?.totalPages)}
                      color="primary"
                      variant="outlined"
                      disabled={shipmentsInfo?.isLoading ? true : false}
                      renderItem={(item) => (
                        <PaginationItem
                          className='list-paginatiion-item'
                          component={Link}
                          to={{
                            pathname: `${window.location.pathname}`,
                            search: `?page=${parseInt(item.page)}${thisSearch}`
                          }}
                          {...item}
                        />
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row" style={{ margin: 0, padding: 0 }}>
          <div className="col-md-12" style={{ padding: 0 }}>
            <div className="storeCardB">
              <div className="updateButton">
                <div className='overflow' style={{ display: 'flex', alignItems: 'center' }}>
                  <div style={{ position: 'relative' }}>
                    <form onSubmit={handleSubmit2(searchOrder)} className="table_search_card">
                      <input
                        defaultValue={search}
                        type="text"
                        className="form-control table_search"
                        placeholder="Search for user..."
                        id="orderId"
                        aria-invalid={errors2.orderId ? "true" : "false"}
                        {...register2("orderId", { required: true })}
                      />
                      {!search &&
                        <IconButton size='sm' style={{ top: '3px', right: '5px', borderRadius: '100px' }} aria-haspopup="true" type="submit" className="table_search_icon">
                          <SearchIcon style={{ fontSize: 'inherit' }} />
                        </IconButton>
                      }
                      {search &&
                        <IconButton
                          size='sm'
                          style={{ top: '3px', right: '5px', borderRadius: '100px' }}
                          className="table_search_icon"
                          onClick={(() => clearSearch())}
                        >
                          <CloseIcon style={{ fontSize: 'inherit' }} />
                        </IconButton>}
                    </form>
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center', margin: '0px 10px' }}>

                  </div>
                </div>
                <div className="table_search_card">

                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row" style={{ margin: 0, padding: 0 }}>
          <div className="col-md-12" style={{ padding: 0 }}>
            <div className="tabelOverflow" style={{ position: 'relative' }}>
              {getLoadingProgress(shipmentsInfo?.isLoading, shipmentsInfo?.isFetching) &&
                <div className='loading-progress-bg' style={{ position: 'absolute', top: 0, right: 0, left: 0, zIndex: 222, height: '100%', background: '#ffffff82' }}>
                  <LinearProgress style={{ height: '2px' }} />
                </div>
              }
              <table className="table">
                <thead>
                  <tr>
                    <th>
                      AWB
                    </th>
                    <th>Order</th>
                    <th>Image</th>
                    <th>Title</th>
                    <th>Price</th>
                    <th>Shipping To</th>
                    <th className="sticky-column" style={{ textAlign: 'right', position: 'relative' }}>
                      Placed On
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {shipments?.map((item, i) =>
                    <tr key={i}>
                      <td>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                        {item.awb_number}
                        </div>
                      </td>
                      <td>
                        <Link style={{ color: !item.order?.status ? 'tomato' : '#5a5858' }} to={`/order/${item.order?.order_number}`}>
                          {item.order?.order_number}
                        </Link>
                      </td>
                      <td style={{ textAlign: 'center' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Link className="orders-page-item-link" to={`/order/${item.order?.order_number}`}
                              style={{ margin: '0 5px', border: `1px solid #eee`, background: '#fff', padding: '3px', borderRadius: '4px', position: 'relative', zIndex: 111 }}>
                              <img src={`${item.image}`} style={{ width: '35px', height: '35px', objectFit: 'contain' }} />
                              <span
                                style={{
                                  position: 'absolute',
                                  top: '-5px',
                                  right: '-5px',
                                  lineHeight: '15px',
                                  background: '#fe7f4a',
                                  borderRadius: '100px',
                                  width: '15px',
                                  height: '15px',
                                  textAlign: 'center',
                                  fontSize: '12px',
                                  color: '#fff',
                                  fontWeight: 400,
                                }}
                              >{item.quantity}</span>
                            </Link>
                          </div>
                        </div>
                      </td>
                      <td>
                        <Link to={`/order/${item.order?.order_number}`} style={{ color: '#5a5858' }}>
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <img
                              loading="lazy"
                              width="20"
                              src={`https://flagcdn.com/w20/${item.country?.toLowerCase()}.png`}
                              srcSet={`https://flagcdn.com/w40/${item.country?.toLowerCase()}.png 2x`}
                              alt=""
                            />
                            <span className='Ip-title' style={{ margin: '0px 5px', color: !item.order?.status && 'tomato' }}> {item.title}</span>
                          </div>
                        </Link>
                      </td>

                      <td>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          {item.order?.status ?
                            <div>
                              {item.currency}  {Number(item.price)}
                            </div>
                            :
                            <span
                              className="alert alert-danger boot-alert-style">
                              {item.currency}  {Number(item.price)}
                            </span>
                          }
                        </div>
                      </td>
                      <td>
                        <div className="dFlex center nowrap jcr">
                          {item.order?.states === 'cancelled' &&
                            <span className="badge badge-danger">
                              {item.order?.states}
                            </span>
                          }
                          {item.order?.states !== 'cancelled' &&
                            <>
                              {item.state === 'cancelled' &&
                                <span className="badge badge-danger">
                                  {item.state}
                                </span>
                              }
                              {item.state !== 'cancelled' &&
                                <span
                                  className={shippingStatusC(item.shipping_state)?.color}>
                                  {shippingStatusC(item.shipping_state)?.name}
                                </span>
                              }
                            </>
                          }
                          <div style={{ margin: '0px 5px' }}>
                            <span
                              className="alert boot-alert-style" style={{ color: !item.order?.status && 'tomato' }}>
                              {item.order?.country}
                            </span>
                          </div>
                        </div>
                      </td>
                      <td className="sticky-column">
                        <div className="dFlex center nowrap jcr" style={{ justifyContent: 'right', color: !item.order?.status && 'tomato' }}>
                          {Moment(item?.createdAt).format('llll')}
                        </div>

                      </td>
                    </tr>
                  )
                  }
                </tbody>
              </table>
            </div>
            {shipments?.length === 0 ?
              <h5 className="data_not-found">No data available in table</h5>
              : null}
            {shipmentsInfo?.error?.originalStatus === 403 &&
              <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                <div className='alert alert-danger' style={{ background: '#fff0', border: 'none' }}>{shipmentsInfo?.error?.data}</div>
              </div>
            }
          </div>
        </div>
      </div>
    </>
  );
}

export default Shipments;
