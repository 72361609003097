import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Spinner } from 'react-bootstrap'
import './index.scss';
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import "antd/dist/antd.min.css";
import { useSelector } from 'react-redux'
import { useGetAllUsersQuery, useCreateUserMutation, useUpdateUserMutation } from '../../services/users';
import { message, Tag, Switch as Clickable } from "antd";
import Moment from 'moment';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import LinearProgress from '@mui/material/LinearProgress';
import IconButton from '@mui/joy/IconButton';
import Checkbox from '@mui/joy/Checkbox';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Dropdown from '@mui/joy/Dropdown';
import Menu from '@mui/joy/Menu';
import MenuButton from '@mui/joy/MenuButton';
import MenuItem from '@mui/joy/MenuItem';

function Users() {
  const { userId, userToken, userEmail, userName, isStatus, userRole, languageCode, isAdmin } = useSelector((state) => state.auth);
  const history = useHistory();
  const { register, handleSubmit, formState: { errors }, reset } = useForm();
  const location = useLocation();
  const page = new URLSearchParams(location.search).get('page') || 1
  const limit = new URLSearchParams(location.search).get('size') || 15
  const search = new URLSearchParams(location.search).get('search') || null
  const thisSearch = search ? `&search=${search}` : ''

  const [checked, setChecked] = React.useState([]);
  const handleChange = (data) => {
    setChecked(data);
  };

  const usersInfo = useGetAllUsersQuery({
    page: page,
    size: limit,
    search: search,
    userId: userId,
    userToken: userToken,
    userRole: userRole
  }, { count: 1 }, { refetchOnMountOrArgChange: true })
  const users = usersInfo?.data && usersInfo?.data?.users;
  const pagination = usersInfo?.data && usersInfo?.data?.pagination;

  const [updateUser, updateresult] = useUpdateUserMutation()

  useEffect(() => {
    if (updateresult?.isSuccess) {
      setChecked([])
    }
  }, [updateresult]);


  function getLoadingProgress(thisLoading, thisFetching) {
    if (thisLoading) {
      return true
    } else if (thisFetching) {
      return true
    } else {
      return false
    }
  }

  function searchOrder(data) {
    const result = data?.orderId;
    history.push(`${window.location.pathname}?search=${result}`)
  }

  function clearSearch() {
    reset()
    history.push(`${window.location.pathname}`)
  }

  return (
    <>
      <div className="tabel_cards">

        <div className="row" style={{ margin: 0, padding: 0 }}>
          <div className="newFlexPagi">
            <div className="col-md-12">
              <div className="storeCardB">
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', overflow: 'auto', width: '100%', whiteSpace: 'nowrap' }}>
                  <div>Showing {pagination?.totalCounts && pagination?.totalCounts} entries</div>
                  <div className="Order-Pagination">
                    <Pagination
                      className='list-paginatiion-table'
                      style={{ width: '100%', textAlign: 'right', display: 'flex', justifyContent: 'flex-end', padding: '0px' }}
                      page={parseInt(page)}
                      count={parseInt(pagination?.totalPages)}
                      color="primary"
                      variant="outlined"
                      disabled={usersInfo?.isLoading ? true : false}
                      renderItem={(item) => (
                        <PaginationItem
                          className='list-paginatiion-item'
                          component={Link}
                          to={{
                            pathname: `${window.location.pathname}`,
                            search: `?page=${parseInt(item.page)}${thisSearch}`
                          }}
                          {...item}
                        />
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row" style={{ margin: 0, padding: 0 }}>
          <div className="col-md-12" style={{ padding: 0 }}>
            <div className="storeCardB">
              <div className="updateButton">
                <div className='overflow' style={{ display: 'flex', alignItems: 'center' }}>
                  <div style={{ position: 'relative' }}>
                    <form onSubmit={handleSubmit(searchOrder)} className="table_search_card">
                      <input
                        defaultValue={search}
                        type="text"
                        className="form-control table_search"
                        placeholder="Search for user..."
                        id="orderId"
                        aria-invalid={errors.orderId ? "true" : "false"}
                        {...register("orderId", { required: true })}
                      />
                      {!search &&
                        <IconButton size='sm' style={{ top: '3px', right: '5px', borderRadius: '100px' }} aria-haspopup="true" type="submit" className="table_search_icon">
                          <SearchIcon style={{ fontSize: 'inherit' }} />
                        </IconButton>
                      }
                      {search &&
                        <IconButton
                          size='sm'
                          style={{ top: '3px', right: '5px', borderRadius: '100px' }}
                          className="table_search_icon"
                          onClick={(() => clearSearch())}
                        >
                          <CloseIcon style={{ fontSize: 'inherit' }} />
                        </IconButton>}
                    </form>
                  </div>

                  <div style={{ display: 'flex', alignItems: 'center', margin: '0px 10px' }}>
                    <div style={{ position: 'relative' }}>
                      <Dropdown>
                        <MenuButton
                          disabled={!checked?.event}
                          style={{ padding: '0px 7px 0px 7px', borderRadius: '6px', fontWeight: 400, fontSize: '14px', minHeight: '35px' }}
                          className="Header-button-connect">
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <span style={{ textTransform: 'capitalize' }}>Action</span>
                            <ArrowDropDownIcon style={{ fontSize: '1.5rem', margin: '0 3px' }} />
                          </div>
                        </MenuButton>
                        <Menu>
                          <MenuItem
                            disabled={!checked?.event}
                            key={'2'}
                            className='orders-sub-side-menu'
                            onClick={() => updateUser({
                              id: checked?.data?.id,
                              status: checked?.data?.status ? false : true,
                              status_update: 'status_update',
                              userId: userId,
                              userToken: userToken,
                              userRole: userRole
                            })}
                          >
                            <div style={{ display: 'flex', alignItems: 'center', fontSize: '14px' }}>
                              <div style={{ textAlign: 'left', textTransform: 'capitalize' }}>{checked?.data?.status ? 'Disable' : 'Enable'}</div>
                            </div>
                          </MenuItem>
                        </Menu>
                      </Dropdown>
                    </div>
                  </div>
                </div>
                <div className="table_search_card">
                  <div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row" style={{ margin: 0, padding: 0 }}>
          <div className="col-md-12" style={{ padding: 0 }}>
            <div className="tabelOverflow" style={{ position: 'relative' }}>
              {getLoadingProgress(usersInfo?.isLoading, usersInfo?.isFetching) &&
                <div className='loading-progress-bg' style={{ position: 'absolute', top: 0, right: 0, left: 0, zIndex: 222, height: '100%', background: '#ffffff82' }}>
                  <LinearProgress style={{ height: '2px' }} />
                </div>
              }
              <table className="table">
                <thead>
                  <tr>
                    <th style={{ textAlign: 'center' }}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Checkbox disabled size='sm' />
                      </div>
                    </th>
                    <th>ID</th>
                    <th>Email</th>
                    <th>Phone</th>
                    <th>Ref.</th>
                    <th>Region</th>
                    <th>Domain</th>
                    <th>Updated At</th>
                    <th className="sticky-column" style={{ textAlign: 'right' }}>
                      Status
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {users?.map((item, i) =>
                    <tr key={i}>
                      <td>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <Checkbox
                            className='update-check'
                            size="sm"
                            checked={checked?.data?.id === item?.id ? checked?.event : false}
                            onChange={((event) => handleChange({
                              event: event.target.checked,
                              data: item,
                            }))}
                          />
                        </div>
                      </td>
                      <td>
                        <div>{item.id}</div>
                      </td>
                      <td>
                        <Link to={`/orders/user/${item.id}`}>
                          <div> {item.name && item.name + ', '}{item.email ? item.email : item.username}</div>
                        </Link>
                      </td>
                      <td>
                        <div>{item.phone}</div>
                      </td>
                      <td>
                        {item.reference &&
                          <span>{item.reference}</span>
                        }
                      </td>
                      <td>
                        <div>{item.language}-{item.country}</div>
                      </td>
                      <td>
                        <div>{item.domain}</div>
                      </td>
                      <td>
                        <div>{Moment(item.createdAt).format('DD-MM-YYYY')}</div>
                      </td>
                      <td className="sticky-column" style={{ textAlign: 'right' }}>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'right' }}>
                          {item.state &&
                            <Tag className="booksTag" color={"#7dc487"}>
                              <small>{item.state}</small>
                            </Tag>
                          }
                          <div className={item.status ? `badge badge-success` : `badge badge-danger`}>{item.status ? 'Active' : 'Disabled'}</div>

                        </div>
                      </td>
                    </tr>
                  )
                  }
                </tbody>
              </table>
            </div>

            {users?.length === 0 ?
              <h5 className="data_not-found">No data available in table</h5>
              : null}
            {usersInfo?.error?.originalStatus === 403 &&
              <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                <div className='alert alert-danger' style={{ background: '#fff0', border: 'none' }}>{usersInfo?.error?.data}</div>
              </div>
            }

          </div>
        </div>
      </div>
    </>
  );
}

export default Users;
