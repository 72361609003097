import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Modal } from 'react-bootstrap'
import './index.scss';
import "antd/dist/antd.min.css";
import { useStoreProductsQuery, useCreateMutation } from '../../services/products';
import { useSelector } from "react-redux";
import IconButton from '@mui/joy/IconButton';
import Button from '@mui/joy/Button';
import { CKEditor } from 'ckeditor4-react';
import CloseIcon from '@mui/icons-material/Close';
import parse from "html-react-parser";
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import LanguageIcon from '@mui/icons-material/Language';
import LinearProgress from '@mui/material/LinearProgress';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';

function Store() {
  const { userId, userToken, userEmail, userName, status, admin } = useSelector((state) => state.auth);
  const location = useLocation();
  const history = useHistory();
  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const { id } = useParams();
  const [getSearchQuery, searchQuery] = useState("")
  const page = new URLSearchParams(location.search).get('page') || 1
  const limit = new URLSearchParams(location.search).get('size') || 15

  const suppliersData = useStoreProductsQuery({ page: page, size: limit, userId: userId, userToken: userToken, admin: admin, searchquery: getSearchQuery, id: id }, { count: 1 }, { refetchOnMountOrArgChange: true })
  const suppliers = suppliersData?.data?.products;
  const pagination = suppliersData?.data?.pagination;

  const [createNew, createresult] = useCreateMutation()

  const [show, setShow] = useState(false);
  const [getDetails, setDetails] = useState("")

  function handleClose() {
    setShow(false)
  }
  function handleOpen() {
    setShow(true)
  }

  useEffect(() => {
    if (createresult?.isSuccess) {
      setShow(false)
      reset()
    }
  }, [createresult]);


  const postDetails = (evt) => {
    const data = evt.editor.getData();
    setDetails(data);
  }

  function createNewPost(data) {
    createNew({
      title: data.title,
      country: data.country,
      asin: data.asin,
      color: data.color,
      weight: data.weight,
      quantity: data.quantity,
      brand: data.brand,
      condition: data.condition,
      in_stock: data.in_stock,
      price: data.price,
      category: data.category,
      tags: data.tags,
      images: data.images,
      image: data.main_image,
      product_url: data.product_url,
      estimated_delivery: data.estimated_delivery,
      store_id: id,
      features: data.features,
      details: getDetails,
      userId: userId,
      userToken: userToken,
      admin: admin
    })
  }

  function functionIdLink(id, pid, store_id) {
    if (Number(store_id) === 3) {
      return pid
    } else {
      return id
    }
  }

  function getLoadingProgress(thisLoading, thisFetching) {
    if (thisLoading) {
      return true
    } else if (thisFetching) {
      return true
    } else {
      return false
    }
  }

  const handleBack = () => {
    history.goBack();
  };

  return (
    <div className="tabel_cards">

      <div style={{ display: 'none' }}>
        <CKEditor
          style={{ display: 'none' }}
          data={``}
          config={{
            extraPlugins: "justify,showblocks",
            toolbar: [
              ['Source'],
              ['Styles', 'Format', 'Font', 'FontSize'],
              ['Bold', 'Italic'],
              ['Undo', 'Redo'],
              ['EasyImageUpload'],
              ['JustifyLeft', 'JustifyCenter', 'JustifyRight'],
              ['Maximize']
            ],
            height: '220px',
            allowedContent: false,
            direction: 'rtl',
            language: 'en',
            pasteFromWordRemoveFontStyles: false
          }}
          id="template_html"
        />
      </div>
 
      <Modal className="new-post-modal" show={show} onHide={handleClose} centered animation={true}>
        <Modal.Body>
          <IconButton
            style={{ cursor: 'pointer', position: 'absolute', right: '10px', top: '10px', zIndex: 111 }}
            onClick={handleClose}
          >
            <CloseIcon className="Header-close-modal-icon" />
          </IconButton>
          <div className="row">
            <div className="col-md-12">
              <h5 style={{ textAlign: 'center', padding: '10px' }}>Add New Product</h5>
            </div>
          </div>
          <form onSubmit={handleSubmit(createNewPost)}>
            <div className="row">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Title</label>
                      <input type="text" className="form-control" id="title"
                        aria-invalid={errors.title ? "true" : "false"}
                        {...register('title', { required: true })} />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label>Country</label>
                      <select className="form-control" id="country"
                        aria-invalid={errors.country ? "true" : "false"}
                        {...register('country', { required: true })}>
                        <option value={'AE'} selected>UAE</option>
                        <option value={'US'}>United State</option>
                        <option value={'UK'}>United Kingdom</option>

                      </select>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label>Price in USD</label>
                      <input type="number" className="form-control" id="price"
                        aria-invalid={errors.price ? "true" : "false"}
                        {...register('price', { required: true })} />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label>Weight in Grams</label>
                      <input type="number" className="form-control" id="weight"
                        aria-invalid={errors.weight ? "true" : "false"}
                        {...register('weight', { required: true })} />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label>UID</label>
                      <input type="text" className="form-control" id="asin"
                        aria-invalid={errors.asin ? "true" : "false"}
                        {...register('asin', { required: true })} />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label>Condition</label>
                      <input type="text" className="form-control" id="condition"
                        aria-invalid={errors.condition ? "true" : "false"}
                        {...register('condition', { required: true })} />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label>Color</label>
                      <input type="text" className="form-control" id="color"
                        aria-invalid={errors.color ? "true" : "false"}
                        {...register('color', { required: true })} />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label>Brand</label>
                      <input type="text" className="form-control" id="brand"
                        aria-invalid={errors.brand ? "true" : "false"}
                        {...register('brand', { required: true })} />
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="form-group">
                      <label>In Stock</label>
                      <select className='form-control' id="in_stock"
                        aria-invalid={errors.in_stock ? "true" : "false"}
                        {...register('in_stock', { required: true })}>
                        <option disabled selected value={''}>Select</option>
                        <option value={'in_stock'}>In Stock</option>
                        <option value={'out_of_stock'}>Out of Stock</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label>Quantity</label>
                      <input type="number" className="form-control" id="quantity"
                        aria-invalid={errors.quantity ? "true" : "false"}
                        {...register('quantity', { required: true })} />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label>Delivery in Days</label>
                      <input type="number" className="form-control" id="estimated_delivery"
                        aria-invalid={errors.estimated_delivery ? "true" : "false"}
                        {...register('estimated_delivery', { required: true })} />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label>Image urls</label>
                      <input type="text" className="form-control" id="images"
                        aria-invalid={errors.images ? "true" : "false"}
                        {...register('images', { required: false })} />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label>Main image</label>
                      <input type="text" className="form-control" id="main_image"
                        aria-invalid={errors.main_image ? "true" : "false"}
                        {...register('main_image', { required: false })} />
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Features</label>
                      <textarea className="form-control" id="features"
                        aria-invalid={errors.features ? "true" : "false"}
                        {...register('features', { required: false })}>
                      </textarea>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Details</label>
                      <CKEditor
                        data={``}
                        config={{
                          extraPlugins: "justify,showblocks",
                          toolbar: [
                            ['Source'],
                            ['Styles', 'Format', 'Font', 'FontSize'],
                            ['Bold', 'Italic'],
                            ['Undo', 'Redo'],
                            ['EasyImageUpload'],
                            ['JustifyLeft', 'JustifyCenter', 'JustifyRight'],
                            ['Maximize']
                          ],
                          height: '220px',
                          allowedContent: false,
                          direction: 'rtl',
                          language: 'en',
                          pasteFromWordRemoveFontStyles: false
                        }}
                        id="template_html"
                        onChange={postDetails}
                        initData={parse(``)} />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group" style={{ justifyContent: 'flex-end', display: 'flex' }}>
                      <Button disabled={createresult?.isLoading ? true : false}
                        color="info"
                        variant="contained"
                        size="small"
                        style={{ width: 'auto', height: '40px', background: '#2c2245cf', color: '#f7f7f7' }}
                        type="submit">
                        {createresult?.isLoading ? 'Loading...' : 'Create'}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>



      <div className="row" style={{ margin: 0, padding: 0 }}>
        <div className="newFlexPagi">
          <div className="col-md-12">
            <div className="storeCardB">
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', overflow: 'auto', width: '100%', whiteSpace: 'nowrap', flexWrap: 'wrap' }}>
                <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                  <IconButton size='sm' variant='soft' onClick={handleBack}><ArrowBackOutlinedIcon /></IconButton>
                  <div style={{ margin: '0px 10px' }}>Showing {pagination?.totalCounts && pagination?.totalCounts} entries</div>
                </div>
                <div className="Order-Pagination">
                  <Pagination
                    style={{ width: '100%', textAlign: 'right', display: 'flex', justifyContent: 'flex-end', padding: '0px 0px 0px 0px' }}
                    page={parseInt(page)}
                    count={parseInt(pagination?.totalPages)}
                    color="primary"
                    variant="outlined"
                    renderItem={(item) => (
                      <PaginationItem
                        className='list-paginatiion-item'
                        component={Link}
                        to={{ pathname: `${window.location.pathname}`, search: parseInt(item.page) === 1 ? '' : `?page=${parseInt(item.page)}` }}
                        {...item}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div className="row" style={{ margin: 0, padding: 0 }}>
        <div className="col-md-12" style={{ padding: 0 }}>
          <div className="storeCardB">
            <div className="updateButton">
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ position: 'relative', marginRight: '10px' }}>
                </div>
              </div>
              <div className="table_search_card">
                <div>
                  <Button
                    style={{ margin: '0 10px', fontWeight: 400 }}
                    variant="soft"
                    size="sm"
                    onClick={() => handleOpen(true)}>
                    Add Single Product
                  </Button>
                </div>
                <div>

                  <Button
                    style={{ margin: '0 10px', fontWeight: 400 }}
                    variant="soft"
                    size="sm"
                    component={Link}
                    to={`/store/${id}/import`}
                  >
                    Import Products
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div className="row" style={{ margin: 0, padding: 0 }}>
        <div className="col-md-12" style={{ padding: 0 }}>
          <div className="tabelOverflow" style={{ position: 'relative' }}>
            {getLoadingProgress(suppliersData?.isLoading, suppliersData?.isFetching) &&
              <div className='loading-progress-bg' style={{ position: 'absolute', top: 0, right: 0, left: 0, zIndex: 222, height: '100%', background: '#ffffff82' }}>
                <LinearProgress style={{ height: '2px' }} />
              </div>
            }
            <table className="table">
              <thead>
                <tr>
                  <th style={{ width: '0px' }}>ID</th>
                  <th style={{ textAlign: 'center' }}>Image</th>
                  <th>Name</th>
                  <th>Price</th>
                  <th>Country</th>
                  <th className="sticky-column tar">Status</th>
                </tr>
              </thead>
              <tbody>
                {suppliers?.map((item, i) =>
                  <tr key={i}>
                    <td>{functionIdLink(item.id, item.pid, item.store_id)}</td>
                    <td style={{ textAlign: 'center' }}>

                      {item.images ?
                        <img src={`${item.images?.split(',')[0]}`} style={{ width: '35px', height: '35px', objectFit: 'contain' }} /> :
                        <img src={`${item.image}`} style={{ width: '35px', height: '35px', objectFit: 'contain' }} />
                      }
                    </td>
                    <td>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <IconButton
                          onClick={() => window.open(`https://www.thwifty.com/product/${item.store_id}/${functionIdLink(item.id, item.pid, item.store_id)}`, '_blank').focus()}
                        >
                          <LanguageIcon style={{ fontSize: '12px' }} />
                        </IconButton>

                        <Link to={`/product/${item.id}`} style={{ whiteSpace: 'initial' }}>
                          {item.title}
                        </Link>
                      </div>
                    </td>
                    <td>{item.currency} {item.price}</td>
                    <td>{item.country}</td>
                    <td className="sticky-column">
                      <div className="dFlex center nowrap jcr">
                      <span className={`badge ${item.status ? `badge-danger` : `badge-success`}`}>
                          {item.status ? 'inactive' : 'active'}
                        </span>
                        <span style={{margin: '0px 10px'}} className={`badge ${item.in_stock ? `badge-success` : `badge-danger`}`}>
                          {item.in_stock ? 'in stock' : 'out of stock'}
                        </span>
                        <span className={`badge ${item.google_merchant ? `badge-success` : `badge-danger`}`}>
                          {'google merchant'}
                        </span>
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          {suppliers?.length === 0 &&
            <div style={{ padding: '20px', textAlign: 'center' }}>Not Data Found!</div>
          }

        </div>
      </div>
      <br /><br />
    </div>
  );
}

export default Store;
