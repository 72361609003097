import React, { useState, useEffect, Fragment } from 'react';
import { Modal } from 'react-bootstrap'
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import Checkbox from '@mui/joy/Checkbox';

function OrderCancel({ getEditShow, handleCloseEdit, orderData, upResult, userId, userToken, updateOrder, shippingName, shippingEmail }) {
  const [getComment, setComment] = useState('');

  let itemImages = []
  if (orderData?.order_items) {
    orderData?.order_items?.map((item, i) =>
      itemImages.push(item.image)
    );
  }

  const [getEmailSend, setEmailSend] = useState(true);
  const handleChange = (data) => {
    setEmailSend(data);
  };

  return (
    <>

      <Modal className="cancel-modal" show={getEditShow} onHide={handleCloseEdit} centered animation={false}>
        <Modal.Body>
          <IconButton
            style={{ cursor: 'pointer', position: 'absolute', right: '5px', top: '5px', zIndex: 111 }}
            onClick={handleCloseEdit}
          >
            <CloseIcon className="Header-close-modal-icon" />
          </IconButton>
          <div className="row">
            <div className="col-md-12">
              <h5 style={{ textAlign: 'center', padding: '10px' }}>Reason for order cancellation</h5>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="tabelOverflow" style={{ overflow: 'hidden', whiteSpace: 'normal' }}>
              <div style={{ display: 'flex', alignItems: 'center', padding: '10px 0px 10px 0px' }}>
                  <Checkbox
                    className='update-check'
                    size="md"
                    checked={getEmailSend}
                    onChange={((event) => handleChange(event.target.checked))}
                    label="Email to send"
                  />
                </div>
                <div className='form-group'>
                  <label>Comment</label>
                  <textarea className='form-control' required onChange={((e) => setComment(e.target.value))}></textarea>
                </div>
                <div className='form-group'>
                  <Button
                    disabled={!getComment || upResult?.isLoading}
                    onClick={(() =>
                      getComment && updateOrder({
                        name: shippingName,
                        email: shippingEmail,
                        email_send: getEmailSend,
                        domain: orderData?.domain,
                        order_number: orderData?.order_number,
                        images: itemImages && itemImages,
                        id: orderData?.id,
                        state: 'cancel',
                        userId: userId,
                        userToken: userToken,
                        comments: getComment
                      })
                    )}
                    style={{ border: 'none', textTransform: 'capitalize', width: '100%' }}
                    variant="contained"
                    size="medium"
                    color={'info'}
                  >
                    {upResult?.isLoading ? 'updating...' : 'Submit'}

                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )

}

export default OrderCancel;
