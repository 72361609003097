import React, { Component } from 'react';
import './index.scss';

function NotFound() {
  return (
    <div className="page-not-found">
      <div className="row section-card">
        <div className="col-md-12">
          <div>
            <div className='text alert alert-danger'>Page Not Found</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NotFound;
