import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const cachedApi = createApi({

  reducerPath: 'cachedApi',

  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_CONNECT}/`,
  }),

  tagTypes: ['Cached'],
  keepUnusedDataFor: 86400000,
  refetchOnMountOrArgChange: 1,

  endpoints: (builder) => ({

    fetch: builder.query({
      query: (data) => ({
        url: `cached?page=${data.page}&size=${data.size}${data.query ? `&query=${data.query}` : ''}`,
        method: 'GET',
        headers: {
          'Access-Key': localStorage.getItem('accessKey'),
          'X-User-Token': data.userToken,
          'X-User-Id': data.userId,
          'X-User-Role': data.admin,
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },

      }),
      keepUnusedDataFor: 86400000,
      providesTags: ['Cached'],
    }),


    update: builder.mutation({
      query: (data) => {
        return {
          url: `cached/${data.id}`,
          method: 'PUT',
          body: data,
          headers: {
            'Access-Key': localStorage.getItem('accessKey'),
            'X-User-Token': data.userToken,
            'X-User-Id': data.userId,
            'X-User-Role': data.admin,
            'Accept': 'application/json',
            'Content-type': 'application/json; charset=UTF-8',
          }
        }
      },
      // keepUnusedDataFor: 1,
      invalidatesTags: ['Cached'],
    }),

    delete: builder.mutation({
      query: (data) => {
        return {
          url: `cached/${data.id}`,
          method: 'DELETE',
          body: data,
          headers: {
            'Access-Key': localStorage.getItem('accessKey'),
            'X-User-Token': data.userToken,
            'X-User-Id': data.userId,
            'X-User-Role': data.admin,
            'Accept': 'application/json',
            'Content-type': 'application/json; charset=UTF-8',
          }
        }
      },
      // keepUnusedDataFor: 1,
      invalidatesTags: ['Cached'],
    }),


  }),

})

export const { useFetchQuery, useUpdateMutation, useDeleteMutation } = cachedApi