import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation, useParams } from "react-router-dom";
import './index.scss';
import RouteOne from './RouteOne';
import RouteTwo from './RouteTwo';

function Countries() {

  const { route } = useParams();

 
  return (
    <>
      {route === 'routeOne' &&
        <RouteOne route={route} />
      }
      {route === 'routeTwo' &&
        <RouteTwo route={route} />
      }

    </>

  );
}

export default Countries;
