import React, { useState, useEffect } from 'react';
import { Link, useHistory, useLocation } from "react-router-dom";
import './index.scss';
import "antd/dist/antd.min.css";
import { useFetchQuery, useDeleteMutation, useUpdateMutation } from '../../services/products';
import { useSelector } from "react-redux";
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import LinearProgress from '@mui/material/LinearProgress';
import Loader from '../../Helper/Loader';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import ProductsList from './ProductsList';
function ProductsC({ page, limit, inSearch, popular }) {
  const { userId, userToken, userEmail, userName, status, admin } = useSelector((state) => state.auth);
  const history = useHistory();
  const suppliersData = useFetchQuery({ page: page, size: limit, userId: userId, userToken: userToken, admin: admin, inSearch: inSearch, popular: popular }, { count: 1 }, { refetchOnMountOrArgChange: true })
  const suppliers = suppliersData?.data && suppliersData?.data?.products;
  const pagination = suppliersData?.data && suppliersData?.data?.pagination;

  const [deleteList, deleteresult] = useDeleteMutation()

  function getLoadingProgress(thisLoading, thisFetching) {
    if (thisLoading) {
      return true
    } else if (thisFetching) {
      return true
    } else {
      return false
    }
  }

  return (
    <div className="tabel_cards">

      <div className="row" style={{ margin: 0, padding: 0 }}>
        <div className="newFlexPagi">
          <div className="col-md-12">
            <div className="storeCardB">
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', overflow: 'auto', width: '100%', whiteSpace: 'nowrap' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <span> Showing {pagination?.totalCounts && pagination?.totalCounts} entries &nbsp; &nbsp;</span>
                  {suppliersData?.isFetching && <Loader style={{ margin: '3px 0px' }} />}
                </div>
                <div className="Order-Pagination">
                  <Pagination
                    style={{ width: '100%', textAlign: 'right', display: 'flex', justifyContent: 'flex-end', padding: '0px 0px 0px 0px' }}
                    page={parseInt(page)}
                    count={parseInt(pagination?.totalPages)}
                    color="primary"
                    variant="outlined"
                    renderItem={(item) => (
                      <PaginationItem
                        className='list-paginatiion-item'
                        component={Link}
                        to={{ pathname: `${window.location.pathname}`, search: parseInt(item.page) === 1 ? '' : `?page=${parseInt(item.page)}${inSearch && `&in_search=${inSearch}`}${popular && `&popular=${popular}`}` }}
                        {...item}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row" style={{ margin: 0, padding: 0 }}>
        <div className="col-md-12" style={{ padding: 0 }}>
          <div className="storeCardB">
            <div className="updateButton">
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ position: 'relative', marginRight: '10px' }}>

                </div>
              </div>
              <div className="table_search_card">
                <FormControlLabel
                  control={<Checkbox
                    onChange={(() => history.push(Number(popular) === 1 ? `?page=1` : `?page=1&popular=1`))}
                    checked={Number(popular) === 1 ? true : false} />} label="Popular" />
                <FormControlLabel
                  control={<Checkbox
                    onChange={(() => history.push(Number(inSearch) === 1 ? `?page=1` : `?page=1&in_search=1`))}
                    checked={Number(inSearch) === 1 ? true : false} />} label="Trending" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row" style={{ margin: 0, padding: 0 }}>
        <div className="col-md-12" style={{ padding: 0 }}>
          <div className="tabelOverflow" style={{ position: 'relative' }}>
            {getLoadingProgress(suppliersData?.isLoading, suppliersData?.isFetching) &&
              <div className='loading-progress-bg' style={{ position: 'absolute', top: 0, right: 0, left: 0, zIndex: 222, height: '100%', background: '#ffffff82' }}>
                <LinearProgress style={{ height: '2px' }} />
              </div>
            }
            <table className="table">
              <thead>
                <tr>
                  <th style={{ width: '0px' }}>ID</th>
                  <th style={{ textAlign: 'center' }}>Image</th>
                  <th>Name</th>
                  <th>Price</th>
                  <th>Country</th>
                  <th className="sticky-column tar" style={{ textAlign: 'right', width: '1px' }}>Status</th>
                </tr>
              </thead>
              <tbody style={{ position: 'relative' }}>
                <ProductsList suppliersData={suppliersData} deleteList={deleteList} deleteresult={deleteresult} />
              </tbody>
            </table>
            {suppliersData?.error?.originalStatus === 403 &&
              <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                <div className='alert alert-danger' style={{ background: '#fff0', border: 'none' }}>{suppliersData?.error?.data}</div>
              </div>
            }
          </div>
          {suppliers?.length === 0 &&
            <div style={{ padding: '20px', textAlign: 'center' }}>Not Data Found!</div>
          }
        </div>
      </div>
      <br />
      <br />
    </div>
  );
}

export default ProductsC;
