import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Spinner, Modal } from 'react-bootstrap'
import './index.scss';
import SearchIcon from "@material-ui/icons/Search";
import "antd/dist/antd.min.css";
import { useSelector } from 'react-redux'
import { useFetchQuery, useCreateMutation, useUpdateMutation, useDeleteMutation } from '../../services/coupons';
import Button from '@mui/joy/Button';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/joy/IconButton';
import LinearProgress from '@mui/material/LinearProgress';
import PageError from '../PageError';
function Coupons() {
  const { userId, userToken, userEmail, userName, isStatus, userRole, languageCode, isAdmin, admin } = useSelector((state) => state.auth);
  const history = useHistory();
  const { register, handleSubmit, formState: { errors }, reset } = useForm();
  const location = useLocation();
  const page = new URLSearchParams(location.search).get('page') || 1
  const limit = new URLSearchParams(location.search).get('size') || 15



  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);



  const [getSearchId, setSearchId] = useState("")
  const [getNewModal, setNewModal] = useState(false)
  const [getOthersModal, setOthersModal] = useState(false)
  const [getName, setName] = useState('')
  const [getUsername, setUsername] = useState('')
  const [getReference, setReference] = useState('')



  const [deleteList, deleteresult] = useDeleteMutation()

  const couponsInfo = useFetchQuery({
    page: page,
    size: limit,
    searchid: getSearchId,
    userId: userId,
    userToken: userToken,
    userRole: userRole
  }, { count: 1 }, { refetchOnMountOrArgChange: true })
  const couponsData = couponsInfo?.data?.results;
  const pagination = couponsInfo?.data?.pagination;

  const [createUser, createresult] = useCreateMutation()


  function ebtidaEModal() {
    setNewModal(true)
  }
  function closeNewModal() {
    setNewModal(false)
  }

  useEffect(() => {
    if (createresult?.isSuccess) {
      setNewModal(false)
      setOthersModal(false)
      reset()
    }

  }, [createresult]);




  function getLoadingProgress(thisLoading, thisFetching) {
    if (thisLoading) {
      return true
    } else if (thisFetching) {
      return true
    } else {
      return false
    }
  }

  return (
    <>
      <div className="tabel_cards">

        <Modal className="user-page-modal" show={getNewModal} onHide={closeNewModal} animation={false}>

          <Modal.Body>

            <IconButton
              style={{ cursor: 'pointer', position: 'absolute', right: '10px', top: '10px', zIndex: 111 }}
              onClick={closeNewModal}
            >
              <CloseIcon className="Header-close-modal-icon" />
            </IconButton>
            <div className="row">
              <div className="col-md-12">
                <h5 style={{ textAlign: 'center', padding: '10px' }}>Add New</h5>
              </div>
            </div>

            <form onSubmit={handleSubmit(createUser)}>
              {createresult?.error?.data?.error &&
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: '12px' }}>
                  <span className="alert alert-danger">{createresult?.error?.data?.error}</span>
                </div>
              }
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Coupon Code</label>
                    <input type="text" className="form-control"
                      id="coupon_code"
                      aria-invalid={errors.coupon_code ? "true" : "false"}
                      {...register('coupon_code', { required: true })}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Coupon For</label>
                    <select className="form-control"
                      id="coupon_for"
                      aria-invalid={errors.coupon_for ? "true" : "false"}
                      {...register('coupon_for', { required: true })}>
                      <option value="" selected disabled>Select</option>
                      <option value={'once'}>Once</option>
                      <option value={'multiple'}>Multiple</option>
                    </select>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group">
                    <label>Value %</label>
                    <input type="number" className="form-control"
                      id="value"
                      aria-invalid={errors.value ? "true" : "false"}
                      {...register('value', { required: true })}
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group">
                    <label>Maximum Value</label>
                    <input type="number" className="form-control"
                      id="maximum_value"
                      aria-invalid={errors.maximum_value ? "true" : "false"}
                      {...register('maximum_value', { required: true })}
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group">
                    <label>Country</label>
                    <select className="form-control"
                      id="country"
                      aria-invalid={errors.country ? "true" : "false"}
                      {...register('country', { required: true })}>
                      <option value="" selected disabled>Select</option>
                      <option value={'AE'}>UAE</option>
                      <option value={'OM'}>Oman</option>
                      <option value={'KW'}>Kuwait</option>
                      <option value={'SA'}>Saudi</option>
                      <option value={'GB'}>UK</option>
                    </select>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group">
                    <label>Validity</label>
                    <select className="form-control"
                      id="validity"
                      aria-invalid={errors.validity ? "true" : "false"}
                      {...register('validity', { required: true })}>
                      <option value="" selected disabled>Select</option>
                      <option value={1 * 24 * 60 * 60}>1 Day</option>
                      <option value={2 * 24 * 60 * 60}>2 Days</option>
                      <option value={3 * 24 * 60 * 60}>3 Days</option>
                      <option value={4 * 24 * 60 * 60}>4 Days</option>
                      <option value={5 * 24 * 60 * 60}>5 Days</option>
                      <option value={6 * 24 * 60 * 60}>6 Days</option>
                      <option value={7 * 24 * 60 * 60}>7 Days</option>
                      <option value={15 * 24 * 60 * 60}>15 Days</option>
                      <option value={30 * 24 * 60 * 60}>30 Days</option>
                    </select>
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="form-group" style={{ justifyContent: 'flex-end', display: 'flex' }}>
                    <Button
                      style={{ fontWeight: 400 }}
                      disabled={createresult.isLoading ? true : false}
                      variant="soft"
                      size="sm"
                      type="submit">
                      {createresult.isLoading ? 'Loading...' : 'Create'}
                    </Button>
                  </div>
                </div>
              </div>
            </form>
          </Modal.Body>
        </Modal>


        <div className="row" style={{ margin: 0, padding: 0 }}>
          <div className="newFlexPagi">
            <div className="col-md-12">
              <div className="storeCardB">
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', overflow: 'auto', width: '100%', whiteSpace: 'nowrap' }}>
                  <div>Showing {pagination?.totalCounts && pagination?.totalCounts} entries</div>
                  <div className="Order-Pagination">

                    <Pagination
                      className='list-paginatiion-table'
                      style={{ width: '100%', textAlign: 'right', display: 'flex', justifyContent: 'flex-end', padding: '0px' }}
                      page={parseInt(page)}
                      count={parseInt(pagination?.totalPages)}
                      color="primary"
                      variant="outlined"
                      disabled={couponsInfo?.isLoading ? true : false}
                      renderItem={(item) => (
                        <PaginationItem
                          className='list-paginatiion-item'
                          component={Link}
                          to={{
                            pathname: `${window.location.pathname}`,
                            search: `?page=${parseInt(item.page)}`
                          }}
                          {...item}
                        />
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row" style={{ margin: 0, padding: 0 }}>
          <div className="col-md-12" style={{ padding: 0 }}>
            <div className="storeCardB">
              <div className="updateButton">
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div style={{ position: 'relative', marginRight: '10px' }}>
                    <input style={{ borderRadius: '100px' }} className="form-control table_search" placeholder="Search..." onChange={(e) => setSearchId(e.target.value)} />
                    <IconButton
                      disabled
                      size='sm'
                      style={{ top: '3px', right: '5px', borderRadius: '100px' }}
                      className="table_search_icon">
                      <SearchIcon style={{ fontSize: 'inherit' }} />
                    </IconButton>
                  </div>
                </div>
                <div className="table_search_card">
                  <div>
                    <Button
                      style={{ fontWeight: 400 }}
                      variant="soft"
                      size="sm"
                      onClick={() => ebtidaEModal()}>
                      Add New
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row" style={{ margin: 0, padding: 0 }}>
          <div className="col-md-12" style={{ padding: 0 }}>
            <div className="tabelOverflow" style={{ position: 'relative' }}>

              {getLoadingProgress(couponsInfo?.isLoading, couponsInfo?.isFetching) &&
                <div className='loading-progress-bg' style={{ position: 'absolute', top: 0, right: 0, left: 0, zIndex: 222, height: '100%', background: '#ffffff82' }}>
                  <LinearProgress style={{ height: '2px' }} />
                </div>
              }
              <table className="table">
                <thead>
                  <tr>
                    <th>Code</th>
                    <th>Code for</th>
                    <th>Value</th>
                    <th>Maximum Value</th>
                    <th>Country</th>
                    <th>Validity</th>
                    <th>Create At</th>
                    <th className="sticky-column" style={{ textAlign: 'right' }}>
                      Action
                    </th>
                  </tr>
                </thead>
                {couponsData?.length === 0 ?
                  <h5 className="data_not-found">No data available</h5>
                  : null}
                <tbody>

                  {couponsData?.map((item, i) =>
                    <tr key={i}>
                      <td>
                        <div>{item.coupon_code}</div>
                      </td>
                      <td>
                        <div> {item.coupon_for}</div>
                      </td>
                      <td>
                        <div>{item.value}</div>
                      </td>
                      <td>
                        <div>{item.maximum_value}</div>
                      </td>
                      <td>
                        <div>{item.country}</div>
                      </td>
                      <td>
                        <div>{Number(item.validity) / 60 / 60} Hours</div>
                      </td>
                      <td>
                        <div>{item.created_at}</div>
                      </td>
                      <td className="sticky-column" style={{ textAlign: 'right' }}>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'right' }}>
                          <Button
                            onClick={() => deleteList({ id: item.coupon_code, userId: userId, userToken: userToken })}
                            variant={`outlined`}
                            size="small"
                            disabled={deleteresult?.isLoading && deleteresult?.originalArgs?.coupon_code === item.id ? true : false}
                          >
                            <small className="dFlex">{`Remove`}</small>
                          </Button>
                        </div>
                      </td>
                    </tr>
                  )
                  }
                </tbody>
              </table>
              {couponsInfo?.error?.originalStatus === 403 &&
                <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                  <div className='alert alert-danger' style={{ background: '#fff0', border: 'none' }}>{couponsInfo?.error?.data}</div>
                </div>
              }
            </div>

          </div>
        </div>
      </div>
      <PageError loading={couponsData?.isLoading} originalStatus={couponsData?.error?.originalStatus} data={couponsData?.error?.data} />
    </>
  );
}

export default Coupons;
