import React, { useState } from 'react';
import { Redirect } from "react-router-dom";
import HeaderMenu from '../components/HeaderMenu'
import './index.scss';
import { useSelector } from 'react-redux'
import { useMediaQuery } from 'react-responsive'
function Admin(props) {
  const { userId, userToken } = useSelector((state) => state.auth);
  const Components = props.component;

  const isMobcreen = useMediaQuery({ query: '(min-width: 0px) and (max-width: 992px)' })
  const [collapsed, setStatesss] = useState(false)
  function toggleCollapsed() {
    setStatesss(!collapsed)
  };
  React.useEffect(() => {
    if (isMobcreen) {
      setStatesss(true)
    } else {
      setStatesss(false)
    }
  }, [isMobcreen]);

  return (
    <div className="page-section">
      <HeaderMenu collapsed={collapsed} toggleCollapsed={toggleCollapsed} />
      <div className={`page-sub-section ${collapsed && 'body-collapsed'}`}>
        {userToken ? <Components /> : <Redirect to="/login"></Redirect>}
      </div>
    </div>
  );
}

export default Admin;
