import React from 'react';
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import IconButton from '@mui/material/IconButton';
import LanguageIcon from '@mui/icons-material/Language';

function ProductsList({ suppliersData, deleteList, deleteresult }) {
  const { userId, userToken, userEmail, userName, status, admin } = useSelector((state) => state.auth);

  function functionIdLink(id, pid, store_id) {
    if (Number(store_id) === 3) {
      return pid
    } else {
      return id
    }
  }

  return (
    <>

      {suppliersData?.data?.products?.map((item, i) =>
        <tr key={i}>
          <td>{functionIdLink(item.id, item.pid, item.store_id)}</td>
          <td style={{ textAlign: 'center' }}>
            {item.images ?
              <img src={`${item.images?.split(',')[0]}`} style={{ width: '35px', height: '35px', objectFit: 'contain' }} /> :
              <img src={`${item.image}`} style={{ width: '35px', height: '35px', objectFit: 'contain' }} />
            }
          </td>
          <td>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <IconButton
                onClick={() => window.open(`https://www.thwifty.com/product/${item.store_id}/${functionIdLink(item.id, item.pid, item.store_id)}`, '_blank').focus()}
              >
                <LanguageIcon style={{ fontSize: '1rem' }} />
              </IconButton>

              <Link to={`/product/${item.id}`} style={{ whiteSpace: 'initial', margin: '0px 5px' }}>
                {item.title}
              </Link>
            </div>
          </td>
          <td>{item.currency} {item.price}</td>
          <td>{item.country}</td>
          <td className="sticky-column">
            <div className="dFlex center nowrap jcr">
              <span className={`badge ${item.status ? `badge-danger` : `badge-success`}`}>
                {item.status ? 'inactive' : 'active'}
              </span>
              <span style={{ margin: '0px 10px' }} className={`badge ${item.in_stock ? `badge-success` : `badge-danger`}`}>
                {item.in_stock ? 'in stock' : 'out of stock'}
              </span>
              <span className={`badge ${item.google_merchant ? `badge-success` : `badge-danger`}`}>
                {'google merchant'}
              </span>
            </div>
          </td>
        </tr>
      )}

    </>
  );
}

export default ProductsList;
