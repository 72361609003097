import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import './index.scss';
import { useFetchUserOrdersQuery } from '../../services/orders';
import { useSelector } from "react-redux";
import moment from 'moment'
import IconButton from '@mui/joy/IconButton';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import LinearProgress from '@mui/material/LinearProgress';
import AtmIcon from '@mui/icons-material/Atm';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import AppleIcon from '@mui/icons-material/Apple';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import PageError from '../PageError';

function UserOrders(props) {
  const { userId, userToken, userEmail, userName, status, admin } = useSelector((state) => state.auth);
  const location = useLocation();
  const { id } = useParams();
  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const [getSearchQuery, searchQuery] = useState("")
  const page = new URLSearchParams(location.search).get('page') || 1
  const limit = new URLSearchParams(location.search).get('size') || 15
  const shippingStatus = new URLSearchParams(location.search).get('shipping_status') || null
  const history = useHistory();
  const ordersData = useFetchUserOrdersQuery({
    id: id,
    page: page,
    size: limit,
    userId: userId,
    userToken: userToken,
    admin: admin,
    shipping_status: shippingStatus,
    userEmail: userEmail
  }, { count: 1 }, { refetchOnMountOrArgChange: true })
  const orders = ordersData?.data?.orders;
  const pagination = ordersData?.data?.pagination;

  const [getOrderId, setOrderId] = useState('');

  const [getEditShow, setEditShow] = useState(false);
  const [getOrderItemsData, setOrderItemsData] = useState([]);

  function handleCloseEdit() {
    setEditShow(false)
  }


  function shippingStatusC(data) {
    let color
    if (data === 'approved') {
      color = 'badge badge-success'
    } else if (data === 'cancelled') {
      color = 'badge badge-danger'
    } else if (data === 'fraud') {
      color = 'badge badge-danger'
    } else {
      color = 'badge badge-warning'
    }
    return { name: data?.replace(/_/g, ' '), color: color }
  }


  function borderColor(data) {
    let color
    if (data === 'delivered') {
      color = '#00800108'
    } else if (data === 'cancelled') {
      color = '#cd201f05'
    } else {
      color = '#ffa5000a'
    }
    return color
  }
  function borderColorSticky(data) {
    let color
    if (data === 'delivered') {
      color = '#f2f4f0'
    } else if (data === 'cancelled') {
      color = '#f8f4f3'
    } else {
      color = '#f7f5ee'
    }
    return color
  }

  function getDeliverydays(data, created) {
    function getTimeline() {
      if (Number(data) === 1) {
        return 'Tomorrow'
      } else {
        return ''
      }
    }
    if (Number(data) > 0) {
      return <><strong>{getTimeline()}</strong> {moment(created).add(Number(data), 'days').format('dddd Do MMM YYYY')}</>
    } else {
      return moment(created).add(10, 'days').format('dddd Do MMM YYYY')
    }
  }

  function functionIdLink(id, pid, store_id) {
    if (Number(store_id) === 3) {
      return pid
    } else {
      return id
    }
  }

  function oiBgColor(data) {
    if (data === 'accepted') {
      return '#008001'
    } else if (data === 'cancelled') {
      return '#cd201f'
    } else {
      return '#ffa500f5'
    }
  }

  function getLoadingProgress(thisLoading, thisFetching) {
    if (thisLoading) {
      return true
    } else if (thisFetching) {
      return true
    } else {
      return false
    }
  }

  function getPaymentTypeIcon(type) {
    if (type === 'card') {
      return <CreditCardIcon style={{ fontSize: '15px' }} />
    } else if (type === 'apple_pay') {
      return <AppleIcon style={{ fontSize: '15px' }} />
    } else if (type === 'bank_transfer') {
      return <AtmIcon style={{ fontSize: '15px' }} />
    } else {
      return ''
    }
  }


  const handleBack = () => {
    history.goBack();
  };


  return (
    <>
      <div className="tabel_cards">

        <div className="row" style={{ margin: 0, padding: 0 }}>
          <div className="newFlexPagi">
            <div className="col-md-12">
              <div>
                <div className="storeCardB">
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', overflow: 'auto', width: '100%', whiteSpace: 'nowrap', flexWrap: 'wrap' }}>
                    <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                      <IconButton variant='soft' size='sm' onClick={handleBack}><ArrowBackOutlinedIcon /></IconButton>
                      <div style={{ margin: '0px 10px' }}>Showing {pagination?.totalCounts && pagination?.totalCounts} entries</div>
                    </div>
                    <div className="Order-Pagination">
                      <Pagination
                        style={{ width: '100%', textAlign: 'right', display: 'flex', justifyContent: 'flex-end', padding: '0px 0px 0px 0px' }}
                        page={parseInt(page)}
                        count={parseInt(pagination?.totalPages)}
                        color="primary"
                        variant="outlined"
                        renderItem={(item) => (
                          <PaginationItem
                            className='list-paginatiion-item'
                            component={Link}
                            to={{
                              pathname: `${window.location.pathname}`,
                              search: parseInt(item.page) === 1 ? `` : `?page=${parseInt(item.page)}`
                            }}
                            {...item}
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>



        <div className="row" style={{ margin: 0, padding: 0, position: 'relative' }}>

          <div style={{ padding: 0, width: '100%' }}>
            <div className="tabelOverflow" style={{ position: 'relative' }}>
              {getLoadingProgress(ordersData?.isLoading, ordersData?.isFetching) &&
                <div className='loading-progress-bg' style={{ position: 'absolute', top: 0, right: 0, left: 0, zIndex: 222, height: '100%', background: '#ffffff82' }}>
                  <LinearProgress style={{ height: '2px' }} />
                </div>
              }
              <table className="table" style={{ margin: 0 }}>
                <thead>
                  <tr style={{ position: 'relative' }}>
                    <div style={{
                      position: 'absolute',
                      right: 0,
                      left: 0,
                      top: 0,
                      height: '100%',
                      background: '#fff0',
                    }}>
                    </div>
                    <th style={{ width: '0px' }}>Order</th>
                    <th>Items</th>
                    <th>Amount</th>
                    <th>Shipping To</th>
                    <th>Domain</th>
                    <th className="sticky-column" style={{ textAlign: 'right' }}>Placed On</th>
                  </tr>
                </thead>
                <tbody>
                  {orders?.map((item, i) =>
                    <tr key={i} style={{ position: 'relative' }}>
                      <div style={{
                        position: 'absolute',
                        right: 0,
                        left: 0,
                        top: 0,
                        height: '100%',
                        background: borderColor(item.shipping_status),
                      }}>
                      </div>
                      <td>
                        <div style={{ display: 'flex', alignItems: 'center' }}>

                          <Link to={`/order/${item.order_number}`} style={{ position: 'relative', zIndex: 111, color: !item.status ? 'tomato' : '#5a5858' }}>
                            {item.order_number}
                          </Link>
                        </div>
                      </td>
                      <td style={{ textAlign: 'center' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          {getPaymentTypeIcon(item.payment_type)}
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            {item?.order_items?.map((order_items, index) =>
                              <Link key={index} className="orders-page-item-link" to={`/order/${item.order_number}`}
                                style={{ margin: '0 5px', border: `1px solid ${borderColor(item.shipping_status)}`, background: '#fff', padding: '3px', borderRadius: '4px', position: 'relative', zIndex: 111 }}>
                                <img src={`${order_items.image}`} style={{ width: '35px', height: '35px', objectFit: 'contain' }} />
                                <span
                                  style={{
                                    position: 'absolute',
                                    top: '-5px',
                                    right: '-5px',
                                    lineHeight: '15px',
                                    background: '#fe7f4a',
                                    borderRadius: '100px',
                                    width: '15px',
                                    height: '15px',
                                    textAlign: 'center',
                                    fontSize: '12px',
                                    color: '#fff',
                                    fontWeight: 400,
                                  }}
                                >{order_items.quantity}</span>
                              </Link>
                            )}
                          </div>
                        </div>
                      </td>
                      <td>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          {item.status ?
                            <div>
                              {item.currency}  {Number(item.amount) + Number(item.shipping_price)}
                            </div>
                            :
                            <span
                              className="alert alert-danger boot-alert-style">
                              {item.currency}  {Number(item.amount) + Number(item.shipping_price)}
                            </span>
                          }
                        </div>
                      </td>
                      <td>
                        <div className="dFlex center nowrap jcr">
                          <span
                            className={shippingStatusC(item.states)?.color}>
                            {shippingStatusC(item.states)?.name}
                          </span>
                          <div style={{ margin: '0px 5px' }}>
                            <span
                              className="alert boot-alert-style" style={{ color: !item.status && 'tomato' }}>
                              {item.country}
                            </span>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div style={{ position: 'relative', zIndex: 111, color: !item.status && 'tomato' }}>{item.domain}</div>
                      </td>
                      <td className="sticky-column" style={{ background: borderColorSticky(item.shipping_status) }}>
                        <div className="dFlex center nowrap jcr" style={{ justifyContent: 'right', color: !item.status && 'tomato' }}>
                          {moment(item?.createdAt).format('llll')}
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            {orders?.length === 0 ?
              <h5 className="data_not-found">No data available in table</h5>
              : null}
          </div>
        </div>
        <br /><br />
      </div>
      <PageError loading={ordersData?.isLoading} originalStatus={ordersData?.error?.originalStatus} data={ordersData?.error?.data} />
    </>
  );
}

export default UserOrders;
