import React, { Component, useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import $ from 'jquery';

function PageError({ isLoading, originalStatus, data }) {
  const closeAlert = () => {
    $("#myAlert").alert('close');
  };


  return (
    !isLoading &&
    <>
      {originalStatus === 403 &&
        <div className="reject-bottom-message" style={{ margin: 0, padding: 0, justifyContent: 'center' }}>
          <div id="myAlert" className='alert alert-danger alert-dismissible fade show' role="alert" style={{ textAlign: 'center' }}>
            {data}
            <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={closeAlert} >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </div>
      }
    </>
  );
}

export default PageError;
