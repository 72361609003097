import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const couponsApi = createApi({
  reducerPath: 'couponsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_CONNECT}/`,
  }),

  tagTypes: ['Coupons'],
  // keepUnusedDataFor: 1,
  refetchOnMountOrArgChange: 1,
  endpoints: (builder) => ({
    fetch: builder.query({
      query: (data) => {
        return {
          url: `coupons`,
          method: 'GET',
          headers: {
            'Access-Key': localStorage.getItem('accessKey'),
            'X-User-Token': data.userToken,
            'X-User-Id': data.userId,
            'X-User-Role': data.userRole,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
        }
      },
      // keepUnusedDataFor: 1,
      providesTags: ['Coupons'],
    }),    

    create: builder.mutation({
      query: (newPost) => ({
        url: `coupons`,
        method: 'POST',
        body: newPost,
        headers: {
          'Access-Key': localStorage.getItem('accessKey'),
          'X-User-Token': localStorage.getItem('userToken'),
          'X-User-Id': localStorage.getItem('userId'),
          'X-User-Role': localStorage.getItem('userRole'),
          'Accept': 'application/json',
          'Content-type': 'application/json; charset=UTF-8',
        }
      }),
      // keepUnusedDataFor: 1,
      invalidatesTags: ['Coupons'],
    }),

    update: builder.mutation({
      query: (data) => {
        return {
          url: `coupons/${data.id}`,
          method: 'PUT',
          body: data,
          headers: {
            'Access-Key': localStorage.getItem('accessKey'),
            'X-User-Token': data.userToken,
            'X-User-Id': data.userId,
            'X-User-Role': data.userRole,
            'Accept': 'application/json',
            'Content-type': 'application/json; charset=UTF-8',
          }
        }
      },
      // keepUnusedDataFor: 1,
      invalidatesTags: ['Coupons'],
    }),

    delete: builder.mutation({
      query: (data) => {
        return {
          url: `coupons/${data.id}`,
          method: 'DELETE',
          body: data,
          headers: {
            'Access-Key': localStorage.getItem('accessKey'),
            'X-User-Token': data.userToken,
            'X-User-Id': data.userId,
            'X-User-Role': data.userRole,
            'Accept': 'application/json',
            'Content-type': 'application/json; charset=UTF-8',
          }
        }
      },
      // keepUnusedDataFor: 1,
      invalidatesTags: ['Coupons'],
    }),


  }),

})

export const { useFetchQuery, useCreateMutation, useUpdateMutation, useDeleteMutation } = couponsApi