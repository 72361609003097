import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, useLocation, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import './index.scss';
import { useSelector } from "react-redux";
import { useDelayedCountQuery } from '../../services/orderItems';

function DelayedCount() {
  const { userId, userToken, userEmail, userName, status, admin } = useSelector((state) => state.auth);
  const location = useLocation();
  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const history = useHistory();


  const { data: data, refetch, isLoading, isFetching } = useDelayedCountQuery({
    userId: userId,
    userToken: userToken,
    admin: admin,
    userEmail: userEmail,
  }, { count: 1 }, { refetchOnMountOrArgChange: true })


  return (
    <div className="col-md-4 list-card-op" style={{ position: 'relative' }}>
      <div className="db-section-card">
        <div style={{ display: 'flex', alignItems: 'center', fontSize: '0.9rem', fontWeight: '400', textTransform: 'uppercase' }}>
          Delayed Items
        </div>
        <div className='db-card-count'>
          {data?.delayed_count}
        </div>
        <div>-10%</div>
      </div>
    </div>

  );
}

export default DelayedCount;
