import React from 'react';
import { useLocation } from "react-router-dom";
import OrdersC from '../../components/OrdersC';

function Orders() {
  const location = useLocation();
  const page = new URLSearchParams(location.search).get('page') || 1
  const limit = new URLSearchParams(location.search).get('size') || 15
  const shippingStatus = new URLSearchParams(location.search).get('shipping_status') || null
  const thisShippingStatus = shippingStatus ? `&shipping_status=${shippingStatus}` : ''
  const paymentStatus = new URLSearchParams(location.search).get('payment_status') || 'true'
  const thisPaymentStatus = paymentStatus ? `&payment_status=${paymentStatus}` : ''
  const paymentMethod = new URLSearchParams(location.search).get('payment_method') || null
  const thisPaymentMethod = paymentMethod ? `&payment_method=${paymentMethod}` : ''
  const assignee = new URLSearchParams(location.search).get('assignee') || null
  const thisAssignee = assignee ? `&assignee=${assignee}` : ''
  const dateRange = new URLSearchParams(location.search).get('date_range') || null
  const thisDateRange = dateRange ? `&date_range=${dateRange}` : ''

  return (
    <>
      <OrdersC
        page={page}
        limit={limit}
        shippingStatus={shippingStatus}
        thisShippingStatus={thisShippingStatus}
        paymentStatus={paymentStatus}
        thisPaymentStatus={thisPaymentStatus}
        paymentMethod={paymentMethod}
        thisPaymentMethod={thisPaymentMethod}
        assignee={assignee}
        thisAssignee={thisAssignee}
        thisDateRange={thisDateRange}
      />
    </>
  );
}

export default Orders;
