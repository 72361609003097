const ShippingCountry  = [
  {
      "city_code": "140001",
      "city_name": "Ropnagar",
      "country_code": "IND",
      "country_name": "India"
  },
  {
      "city_code": "453500",
      "city_name": "Xinxiang",
      "country_code": "CN",
      "country_name": "China"
  },
  {
      "city_code": "518000",
      "city_name": "Shenzhen",
      "country_code": "CN",
      "country_name": "China"
  },
  {
      "city_code": "AALI",
      "city_name": "Aali",
      "country_code": "BAH",
      "country_name": "Bahrain"
  },
  {
      "city_code": "AAN",
      "city_name": "Al Ain",
      "country_code": "AE",
      "country_name": "United Arab Emirates"
  },
  {
      "city_code": "ABHA",
      "city_name": "ABHA",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "ABQAIQ",
      "city_name": "Abqaiq",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "Abufatira",
      "city_name": "Abufatira",
      "country_code": "KUW",
      "country_name": "Kuwait"
  },
  {
      "city_code": "ADHAM",
      "city_name": "Adham",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "AFIF",
      "city_name": "Afif",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "AFLAJ",
      "city_name": "Al AFlaj",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "AFRICA",
      "city_name": "africa",
      "country_code": "Africa",
      "country_name": "south africa"
  },
  {
      "city_code": "AHSA",
      "city_name": "Al Ahsa",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "AINDAR",
      "city_name": "Ain Dar",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "AINKHALED",
      "city_name": "Ain Khaled",
      "country_code": "QAR",
      "country_name": "Qatar"
  },
  {
      "city_code": "AINSINAN",
      "city_name": "Ain Sinan",
      "country_code": "QAR",
      "country_name": "Qatar"
  },
  {
      "city_code": "AJF",
      "city_name": "Jouf",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "AJM",
      "city_name": "Ajman",
      "country_code": "AE",
      "country_name": "United Arab Emirates"
  },
  {
      "city_code": "AK",
      "city_name": "Alaska",
      "country_code": "USA",
      "country_name": "United States of America"
  },
  {
      "city_code": "AL",
      "city_name": "Alabama",
      "country_code": "USA",
      "country_name": "United States of America"
  },
  {
      "city_code": "ALAIS",
      "city_name": "Al Ais",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "ALARDAH",
      "city_name": "Alardhah",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "ALASYAH",
      "city_name": "alasyah",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "ALBIRK",
      "city_name": "Albirk",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "ALDAYER",
      "city_name": "Al Dayer",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "ALDEREYA",
      "city_name": "Aldereya",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "ALGHAT",
      "city_name": "AL GHAT",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "ALMANSOURA",
      "city_name": "Al Mansoura",
      "country_code": "QAR",
      "country_name": "Qatar"
  },
  {
      "city_code": "ALMUWAYH",
      "city_name": "Muwayh",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "ALRAYN",
      "city_name": "Ar Rayn",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "ALRAYYAN",
      "city_name": "Al Rayyan",
      "country_code": "QAR",
      "country_name": "Qatar"
  },
  {
      "city_code": "ALSADD",
      "city_name": "Al Sadd",
      "country_code": "QAR",
      "country_name": "Qatar"
  },
  {
      "city_code": "ALWAKRAH",
      "city_name": "Al Wakrah",
      "country_code": "QAR",
      "country_name": "Qatar"
  },
  {
      "city_code": "ALWUKAIR",
      "city_name": "Al Wukair",
      "country_code": "QAR",
      "country_name": "Qatar"
  },
  {
      "city_code": "Amman",
      "city_name": "Amman",
      "country_code": "Jordan",
      "country_name": "Jordan"
  },
  {
      "city_code": "ANAK",
      "city_name": "Anak",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "AQIQ",
      "city_name": "Aqiq",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "AR",
      "city_name": "Arkansas",
      "country_code": "USA",
      "country_name": "United States of America"
  },
  {
      "city_code": "ARAD",
      "city_name": "Arad",
      "country_code": "BAH",
      "country_name": "Bahrain"
  },
  {
      "city_code": "ARAR",
      "city_name": "Arar",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "ARDIYAT",
      "city_name": "Al Ardiyat",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "AREISH",
      "city_name": "Abu Areish",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "ARISH",
      "city_name": "Abu Arish",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "ARTAWIAH",
      "city_name": "Artawiah",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "ASFAN",
      "city_name": "Asfan",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "ATAWLAH",
      "city_name": "Atawlah",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "AUH",
      "city_name": "Abu Dhabi",
      "country_code": "AE",
      "country_name": "United Arab Emirates"
  },
  {
      "city_code": "AYUN",
      "city_name": "Ayun",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "AZ",
      "city_name": "Arizona",
      "country_code": "USA",
      "country_name": "United States of America"
  },
  {
      "city_code": "BADAYA",
      "city_name": "Badaya",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "BADR",
      "city_name": "Badr",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "Baghdad",
      "city_name": "Baghdad",
      "country_code": "Iraq",
      "country_name": "Iraq"
  },
  {
      "city_code": "BAH",
      "city_name": "BAH",
      "country_code": "BAH",
      "country_name": "Bahrain"
  },
  {
      "city_code": "BAHA",
      "city_name": "Baha",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "BAHAH",
      "city_name": "Al Bahah",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "BAHRAH",
      "city_name": "Bahrah",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "BALASMAR",
      "city_name": "Balasmar",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "BAQA",
      "city_name": "Baqaa",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "BARIQ",
      "city_name": "Bariq",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "BASHAYER",
      "city_name": "Bashayer",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "BATHA",
      "city_name": "Batha",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "BC",
      "city_name": "Belgium CIty",
      "country_code": "Belgium",
      "country_name": "Belgium"
  },
  {
      "city_code": "BILJURASHI",
      "city_name": "Biljurashi",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "BISHA",
      "city_name": "Bisha",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "BISSHA",
      "city_name": "BISSHA",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "BJDI",
      "city_name": "Bijadiyah",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "BKRI",
      "city_name": "Bikeryah",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "BOS",
      "city_name": "Boston",
      "country_code": "USA",
      "country_name": "United States of America"
  },
  {
      "city_code": "BUQAYQ",
      "city_name": "Buqayq",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "BURAIMI",
      "city_name": "BURAIMI",
      "country_code": "Oman",
      "country_name": "Oman"
  },
  {
      "city_code": "BURAYDAH",
      "city_name": "Buraydah",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "BURHAMA",
      "city_name": "burhama",
      "country_code": "BAH",
      "country_name": "Bahrain"
  },
  {
      "city_code": "CALIFORNIA",
      "city_name": "california",
      "country_code": "USA",
      "country_name": "United States of America"
  },
  {
      "city_code": "DAMMAM",
      "city_name": "DAMMAM",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "DARB",
      "city_name": "Darb",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "DAWADMI",
      "city_name": "Dawadmi",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "DAWASIR",
      "city_name": "Wadi al Dawasir",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "DAWMAT",
      "city_name": "Dawmat Al Jandal",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "DAYER",
      "city_name": "Al Dayer",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "DHAHRAN",
      "city_name": "Dhahran",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "DHAHS",
      "city_name": "Dhahran Aljanoub",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "DHALIM",
      "city_name": "Dhalim",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "DHAMAD",
      "city_name": "Dhamad",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "DHURMA",
      "city_name": "Dhurma",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "DILAM",
      "city_name": "Dilam",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "Doha",
      "city_name": "Doha",
      "country_code": "QAR",
      "country_name": "Qatar"
  },
  {
      "city_code": "DUBA",
      "city_name": "Duba",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "DUHAIL",
      "city_name": "Duhail",
      "country_code": "QAR",
      "country_name": "Qatar"
  },
  {
      "city_code": "DXB",
      "city_name": "Dubai",
      "country_code": "AE",
      "country_name": "United Arab Emirates"
  },
  {
      "city_code": "EDABI",
      "city_name": "Edabi",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "FARASAN",
      "city_name": "Farasan",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "FC",
      "city_name": "France City",
      "country_code": "France",
      "country_name": "France"
  },
  {
      "city_code": "Frankfurt",
      "city_name": "Frankfurt",
      "country_code": "Germany",
      "country_name": "Germany"
  },
  {
      "city_code": "FUJ",
      "city_name": "Fujairah",
      "country_code": "AE",
      "country_name": "United Arab Emirates"
  },
  {
      "city_code": "GIZAN",
      "city_name": "gizan",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "GVA",
      "city_name": "Geneva",
      "country_code": "CH",
      "country_name": "Switzerland"
  },
  {
      "city_code": "HAFAR",
      "city_name": "Hafar Al Batin",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "HAFER",
      "city_name": "Hafer Al Batin",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "HAIL",
      "city_name": "HAIL",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "HAIT",
      "city_name": "Al Hait",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "HALI",
      "city_name": "Wadi Hali",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "HAMAD",
      "city_name": "Hamad Town",
      "country_code": "BAH",
      "country_name": "Bahrain"
  },
  {
      "city_code": "HANAKIAH",
      "city_name": "Hanakiyah",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "HAQL",
      "city_name": "Haql",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "HARIQ",
      "city_name": "Hariq",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "HASHBAL",
      "city_name": "Wadi Bin Hashbal",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "HASSA",
      "city_name": "AL HASSA",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "Hatten",
      "city_name": "Hatten",
      "country_code": "KUW",
      "country_name": "Kuwait"
  },
  {
      "city_code": "HAWIYAH",
      "city_name": "Haweyah",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "HAWTM",
      "city_name": "Hawtat Bani Tamim",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "HAWTS",
      "city_name": "Hawtat Sudayr",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "HBD",
      "city_name": "Hyderabad",
      "country_code": "IND",
      "country_name": "India"
  },
  {
      "city_code": "HERZLIYYA",
      "city_name": "Herzliyya",
      "country_code": "ISRAEL",
      "country_name": "ISRAEL"
  },
  {
      "city_code": "HISA",
      "city_name": "hisa",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "HOFUF",
      "city_name": "Hofuf",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "HUBUNA",
      "city_name": "Hubuna",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "HURAYM",
      "city_name": "Huraymila",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "IBRI",
      "city_name": "IBRI",
      "country_code": "Oman",
      "country_name": "Oman"
  },
  {
      "city_code": "Islamabad",
      "city_name": "ISM",
      "country_code": "PK",
      "country_name": "Pakistan"
  },
  {
      "city_code": "ISM",
      "city_name": "Islamabad",
      "country_code": "PK",
      "country_name": "Pakistan"
  },
  {
      "city_code": "ISTANBUL",
      "city_name": "Istanbul",
      "country_code": "Turkey",
      "country_name": "Turkey"
  },
  {
      "city_code": "Jabriya",
      "city_name": "Jabriya",
      "country_code": "KUW",
      "country_name": "Kuwait"
  },
  {
      "city_code": "JAMOUM",
      "city_name": "Jamoum",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "JEDDAH",
      "city_name": "Jeddah",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "JIZAN",
      "city_name": "JIZAN",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "JUBAIL",
      "city_name": "jubail",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "KAUST",
      "city_name": "Thuwal KAUST",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "KFUPM",
      "city_name": "RSC KFUPM",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "KH",
      "city_name": "KHOBAR",
      "country_code": "AE",
      "country_name": "United Arab Emirates"
  },
  {
      "city_code": "KHAFJI",
      "city_name": "Khafji",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "KHAMASIN",
      "city_name": "Khamasin",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "KHAMIS",
      "city_name": "Khamis Mushayt",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "KHARJ",
      "city_name": "KHARJ",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "KHOBAR",
      "city_name": "KHOBAR",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "KHUL",
      "city_name": "Khulais",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "KHURMAH",
      "city_name": "Khurmah",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "KHYBR",
      "city_name": "Khayber",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "KM",
      "city_name": "Khamis Mushait",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "KOL",
      "city_name": "Kolkata",
      "country_code": "IND",
      "country_name": "India"
  },
  {
      "city_code": "KWT",
      "city_name": "Kuwait",
      "country_code": "KUW",
      "country_name": "Kuwait"
  },
  {
      "city_code": "LITH",
      "city_name": "Lith",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "MADINAH",
      "city_name": "MADINAH",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "MAHANI",
      "city_name": "Al Mahani",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "MAHDDH",
      "city_name": "Mahd Ad Dhahab",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "MAJARDA",
      "city_name": "Majarda",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "MAJMAAH",
      "city_name": "Al Majma'ah",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "MAKKAH",
      "city_name": "MAKKAH",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "MANAMA",
      "city_name": "Manama",
      "country_code": "BAH",
      "country_name": "Bahrain"
  },
  {
      "city_code": "MANDAQ",
      "city_name": "Mandaq",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "MARAT",
      "city_name": "Marat",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "MASARHA1",
      "city_name": "Ahad Al Masarhah",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "MASTURAH",
      "city_name": "Masturah",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "MIDNAB",
      "city_name": "Midhnab",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "MILAN",
      "city_name": "Milan",
      "country_code": "italy",
      "country_name": "italy"
  },
  {
      "city_code": "MTR",
      "city_name": "Montpellier",
      "country_code": "France",
      "country_name": "France"
  },
  {
      "city_code": "MUAITHER",
      "city_name": "Muaither",
      "country_code": "QAR",
      "country_name": "Qatar"
  },
  {
      "city_code": "MUBARRAZ",
      "city_name": "Al Mubarraz",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "MUDHAYLIF",
      "city_name": "Mudhaylif",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "MUHARRAQ",
      "city_name": "muharraq",
      "country_code": "BAH",
      "country_name": "Bahrain"
  },
  {
      "city_code": "MUHAYIL",
      "city_name": "Muhayil",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "MUKHWAH",
      "city_name": "Mukhwah",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "Mumbai",
      "city_name": "Mumbai",
      "country_code": "IND",
      "country_name": "India"
  },
  {
      "city_code": "MUQABA",
      "city_name": "Muqaba",
      "country_code": "BAH",
      "country_name": "Bahrain"
  },
  {
      "city_code": "MUSCAT",
      "city_name": "MUSCAT",
      "country_code": "OMN",
      "country_name": "Oman"
  },
  {
      "city_code": "MZM",
      "city_name": "Muzamiyah",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "N.A.",
      "city_name": "Others",
      "country_code": "LB",
      "country_name": "Lebanon"
  },
  {
      "city_code": "NA",
      "city_name": "Others",
      "country_code": "EG",
      "country_name": "Egypt"
  },
  {
      "city_code": "NAIRIYAH",
      "city_name": "Nairiyah",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "NAJD",
      "city_name": "NAJD",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "NAJRAN",
      "city_name": "NAJRAN",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "NAMAS",
      "city_name": "Namas",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "NAMERAH",
      "city_name": "Namerah",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "NEWBURY",
      "city_name": "NEWBURY",
      "country_code": "UK",
      "country_name": "United Kingdom"
  },
  {
      "city_code": "NOWEIRIEH",
      "city_name": "Noweirieh",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "ONAIZA",
      "city_name": "Onaiza",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "ONTARIO",
      "city_name": "ONTARIO",
      "country_code": "CANADA",
      "country_name": "CANADA"
  },
  {
      "city_code": "ORLEANS",
      "city_name": "New Orleans",
      "country_code": "USA",
      "country_name": "United States of America"
  },
  {
      "city_code": "QASSIM",
      "city_name": "Qassim",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "QATIF",
      "city_name": "QATIF",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "QAYSUMAH",
      "city_name": "Qaysumah",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "QLW",
      "city_name": "Qilwah",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "Quezon",
      "city_name": "Quezon City",
      "country_code": "Php",
      "country_name": "Philippines"
  },
  {
      "city_code": "QUL",
      "city_name": "Qaryat Al Ulya",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "QUNFIDAH",
      "city_name": "qunfidah",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "QUNFUDHAH",
      "city_name": "Al Qunfudhah",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "QURAYYAH",
      "city_name": "Qurayyah",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "QUW",
      "city_name": "Quwayiyah",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "RABIGH",
      "city_name": "Rabigh",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "RAF",
      "city_name": "Al Raffah",
      "country_code": "BAH",
      "country_name": "Bahrain"
  },
  {
      "city_code": "RAFAYA",
      "city_name": "Rafaya AlGimsh",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "RAFHA",
      "city_name": "Rafha",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "RAFIAH",
      "city_name": "Rafiah",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "RAFID",
      "city_name": "Ahad Rafidah",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "RAHIMA",
      "city_name": "Rahima",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "RAK",
      "city_name": "Ras Al Khaimah",
      "country_code": "AE",
      "country_name": "United Arab Emirates"
  },
  {
      "city_code": "RANYAH",
      "city_name": "Ranyah",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "RASS",
      "city_name": "Rass",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "RASTANURA",
      "city_name": "Ras Tanura",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "REMAH",
      "city_name": "Remah",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "RIF",
      "city_name": "EAST RIFFA",
      "country_code": "BAH",
      "country_name": "Bahrain"
  },
  {
      "city_code": "RIFFA",
      "city_name": "East Riffa",
      "country_code": "BAH",
      "country_name": "Bahrain"
  },
  {
      "city_code": "RIJAL",
      "city_name": "Rijal Alma",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "RIYADH",
      "city_name": "Riyadh",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "RWD",
      "city_name": "Ruwaydah",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "RYDKHABRA",
      "city_name": "Riyadh Al Khabra",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "SABID",
      "city_name": "Sarat Abida",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "SABT",
      "city_name": "Sabt Al Alaya",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "SABYA",
      "city_name": "Sabya",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "SAFWA",
      "city_name": "safwa",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "SAHAM",
      "city_name": "Saham",
      "country_code": "Oman",
      "country_name": "Oman"
  },
  {
      "city_code": "SAIHAT",
      "city_name": "Saihat",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "SAJIR",
      "city_name": "Sajir",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "SAKAKA",
      "city_name": "Sakaka",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "SAL",
      "city_name": "Salalah",
      "country_code": "OMN",
      "country_name": "Oman"
  },
  {
      "city_code": "SALB",
      "city_name": "Salbukh",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "SALMANIYA",
      "city_name": "Salmaniya",
      "country_code": "BAH",
      "country_name": "Bahrain"
  },
  {
      "city_code": "Salmiya",
      "city_name": "Salmiya",
      "country_code": "KUW",
      "country_name": "Kuwait"
  },
  {
      "city_code": "SAMTHA",
      "city_name": "Samtha",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "SARR",
      "city_name": "SARRAR",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "SHAQRA",
      "city_name": "Shaqra",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "SHARORAH",
      "city_name": "Sharorah",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "SHAROURAH",
      "city_name": "Sharourah",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "SHJ",
      "city_name": "Sharjah",
      "country_code": "AE",
      "country_name": "United Arab Emirates"
  },
  {
      "city_code": "SHQ",
      "city_name": "shaqra",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "SHUQ",
      "city_name": "Shuqayq",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "SKAKAH",
      "city_name": "Skakah",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "SLF",
      "city_name": "Sulayyil",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "TABUK",
      "city_name": "tabuk",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "TAIF",
      "city_name": "TAIF",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "TANUMAH",
      "city_name": "Tanumah",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "TANURA",
      "city_name": "Ras Tannurah",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "TAREEB",
      "city_name": "Tareeb",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "TARUT",
      "city_name": "Tarut",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "TATHLEETH",
      "city_name": "Tathleeth",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "TAYMA",
      "city_name": "Taima",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "THADIQ",
      "city_name": "Thadiq",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "THQB",
      "city_name": "Thqbah",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "TUBARJAL",
      "city_name": "Tubarjal",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "TUI",
      "city_name": "Turayf",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "TUMAIR",
      "city_name": "Tumair",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "TURBAH",
      "city_name": "Turbah",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "TUWAL",
      "city_name": "At Tuwal",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "UAQ",
      "city_name": "Umm Al Quwain",
      "country_code": "AE",
      "country_name": "United Arab Emirates"
  },
  {
      "city_code": "ULA",
      "city_name": "Ula",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "UMLEJ",
      "city_name": "Umlej",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "UQLATS",
      "city_name": "Uqlat As Suqur",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "UYUN",
      "city_name": "Uyun Al Jawa",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "VIRGINA",
      "city_name": "Virgina",
      "country_code": "USA",
      "country_name": "United States of America"
  },
  {
      "city_code": "WADEEN",
      "city_name": "Al Wadeen",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "WAJH",
      "city_name": "Al Wajh",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "WESTRIFFA",
      "city_name": "West Riffa",
      "country_code": "BAH",
      "country_name": "Bahrain"
  },
  {
      "city_code": "YANBU",
      "city_name": "Yanbu",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "YANBUN",
      "city_name": "Yanbu Al Nakhal",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  },
  {
      "city_code": "ZULFI",
      "city_name": "Az Zulfi",
      "country_code": "SA",
      "country_name": "Saudi Arabia"
  }
]

export default ShippingCountry;