import React, { useState, useEffect, Fragment } from 'react';
import DelayedCount from '../../components/Dashboard/DelayedCount';
import DeliveredCount from '../../components/Dashboard/DeliveredCount';
import ProcessingCount from '../../components/Dashboard/ProcessingCount';
import DelayedOrders from '../../components/Dashboard/DelayedOrders';


function Dashboard() {
  return (
    <div className="tabel_cards">
      <div className="row" style={{ margin: 0, padding: 0 }}>
        <DelayedCount />
        <DeliveredCount />
        <ProcessingCount />
      </div>
      <br />
      <DelayedOrders />
    </div>

  );
}

export default Dashboard;
