import React, { useState, useEffect, Fragment } from 'react';
import ManageKeysC from '../../components/ManageKeysC';


function ManageKeys() {
  return (
    <div className="tabel_cards">
      <ManageKeysC />
    </div>

  );
}

export default ManageKeys;
