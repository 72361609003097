import React, { useState, useEffect, Fragment } from 'react';
import { Modal } from 'react-bootstrap'
import IconButton from '@mui/material/IconButton';
import Button from '@mui/joy/Button';
import CloseIcon from '@mui/icons-material/Close';

function ItemWeight({ getItemWeightPopup, cancelItemWeightModal, uitemresult, orderData, getItemId, userId, userToken, updateitem }) {

  const [getWeight, setWeight] = useState(0);

  return (
    <>
      <Modal className="cancel-modal" show={getItemWeightPopup} onHide={cancelItemWeightModal} centered animation={false}>
        <Modal.Body>
          <IconButton
            style={{ cursor: 'pointer', position: 'absolute', right: '5px', top: '5px', zIndex: 111 }}
            onClick={cancelItemWeightModal}
          >
            <CloseIcon className="Header-close-modal-icon" />
          </IconButton>
          <div className="row">
            <div className="col-md-12">
              <h5 style={{ textAlign: 'center', padding: '10px' }}>Update Item Weight</h5>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="tabelOverflow" style={{ overflow: 'hidden', whiteSpace: 'normal' }}>
                <div className='form-group'>
                  <label>Weight in grams</label>
                  <input className='form-control' type='number' required onChange={((e) => setWeight(e.target.value))} />
                </div>
                <div className='form-group'>
                  <Button
                    disabled={!getWeight || uitemresult?.isLoading}
                    onClick={(() =>
                      getWeight && updateitem({
                        orderId: orderData?.id,
                        itemId: getItemId,
                        state: 'update_weight',
                        weight: getWeight,
                        userId: userId,
                        userToken: userToken
                      })
                    )}
                    style={{ border: 'none', textTransform: 'capitalize', width: '100%', fontWeight: 400 }}
                    variant="soft"
                    size="md"
                  >
                    {uitemresult?.isLoading ? 'updating' : 'Submit'}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )

}

export default ItemWeight;
