import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, useLocation, useParams } from "react-router-dom";
import './index.scss';
import { useShowQuery } from '../../services/refund';
import { useSelector } from "react-redux";
import Button from '@mui/material/Button';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import LinearProgress from '@mui/material/LinearProgress';
import PageError from '../PageError';
import Payby from './Payby';
import Stripe from './Stripe';
import IconButton from '@mui/joy/IconButton';

function Refund() {
  const { userId, userToken, userEmail, userName, status, admin } = useSelector((state) => state.auth);
  const { id } = useParams();
  const history = useHistory();

  const orderInfo = useShowQuery({ id: id, userId: userId, userToken: userToken, admin: admin }, { count: 1 }, { refetchOnMountOrArgChange: true })
  const orderData = orderInfo?.data?.order


  const handleBack = () => {
    history.goBack();
  };


  return (
    <>
      <div className="tabel_cards">


        <div className="row" style={{ margin: 0, padding: 0 }}>
          <div className="newFlexPagi">
            <div className="col-md-12">
              <div>
                <div className="storeCardB">
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', overflow: 'auto', width: '100%', whiteSpace: 'nowrap' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <IconButton variant='soft' size='sm' onClick={handleBack}><ArrowBackOutlinedIcon /></IconButton>
                    </div>
                    <div className="Order-Pagination">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {orderInfo?.isLoading &&
          <LinearProgress style={{ height: '2px' }} />
        }


        {orderData?.payment_type === 'payby' ?
          <Payby orderInfo={orderInfo}
          /> : ''
        }

        {orderData?.payment_type === 'apple_pay' ?
          <Stripe orderInfo={orderInfo}
          /> : ''
        }

        {orderData?.payment_type === 'card' ?
          <Stripe orderInfo={orderInfo}
          /> : ''
        }


      </div>
     
    </>
  );
}

export default Refund;
