import React from 'react';
import { useLocation } from "react-router-dom";
import InventoryC from '../../components/InventoryC';

function Inventory() {
  const location = useLocation();
  const page = new URLSearchParams(location.search).get('page') || 1
  const limit = new URLSearchParams(location.search).get('size') || 15

  const query = new URLSearchParams(location.search).get('query') || null
  const thisQuery = query ? `&query=${query}` : ''

  return (
    <>
      <InventoryC
        page={page}
        limit={limit}
        query={query}
        thisQuery={thisQuery}
      />

    </>
  );
}

export default Inventory;
